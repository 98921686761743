
import axios from 'axios';
import { Vue, Component, Ref, Prop, Watch, Emit } from 'vue-property-decorator';
import Ticket from '../../../components/ticketing/Ticket.vue';
import TicketNew from '../../../components/ticketing/TicketNew.vue';
@Component({
  components: {
    Ticket,
    TicketNew,
  },
})
export default class RejectJob extends Vue {
  @Prop({ default: null }) partner!: Object;
  @Prop({ default: null }) jobApiId!: string;
  @Prop({ default: null }) rejectionReasons!: string[];

  reasonCategory: string = '';

  get rejectionReasonsOptions() {
    return this.rejectionReasons;
  }
  get onboardingTicket() {
    return this.partner?.['onboardingTicket']?.ticket;
  }
  async rejectJob() {
    const partnerAccountId: string = this.partner?.['partner_id'];
    const jobToReject: object = {
      job: {
        apiId: this.jobApiId,
      },
      partner: {
        id: partnerAccountId,
      },
      reasonCategory: this.reasonCategory,
    };
    return axios
      .post('/data-api/rejected-job/', {
        ...jobToReject,
      })
      .then(this.triggerRefreshPartners);
  }

  @Emit('ticket-created')
  triggerRefreshTickets() {
    return true;
  }
  @Emit('job-rejected')
  triggerRefreshPartners() {
    return true;
  }
}
