
import HtmlRich from '@gid/vue-common/components/HtmlRich.vue';
import { LiveAgent } from '@gid/vue-common/components/ticketing/interface/ticketing';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import LiveAgentAttachment from './LiveAgentAttachment.vue';

@Component({
  components: {
    LiveAgentAttachment,
    HtmlRich,
  },
  computed: {
    ...mapGetters(['access_token']),
  },
})
export default class LiveAgentMessage extends Vue {
  @Prop({ default: null }) message!: LiveAgent.Message;
  @Prop({ default: null }) ticketId!: Number;
  @Prop({ default: false }) loadHeight!: boolean;

  fromName: string = '';
  fromEmail: string = '';

  created() {
    this.fromDetails();
  }
  get formattedContent() {
    // Regular expression to detect HTML tags
    const pattern = /<[^>]+>/g;
    if (!pattern.test(this.message.content)) {
      return this.message.content.replace(/\n/g, '<br>');
    }
    return this.message.content;
  }
  adjustIframeHeight() {
    this.$nextTick(() => {
      const iframe = this.$refs.iframeRef as HTMLIFrameElement;
      if (iframe && iframe.contentWindow && iframe.contentWindow.document) {
        if (!iframe.dataset.originalHeight) {
          iframe.dataset.originalHeight =
            iframe.contentWindow.document.documentElement.scrollHeight.toString();
        }

        const originalHeight = parseInt(iframe.dataset.originalHeight, 10);
        iframe.style.height = originalHeight + 10 + 'px';
      }
    });
  }
  extractNameAndEmail(str) {
    const regex = /^(.*?)\s*<(.+?)>$/;
    const match = str.match(regex);

    if (match) {
      return { name: match[1].trim(), email: match[2].trim() };
    }

    return null;
  }
  fromDetails() {
    const result = this.extractNameAndEmail(this.message.from);
    this.fromEmail = result?.email;
    this.fromName = result?.name;
  }
  @Watch('loadHeight')
  onLoadHeight(v: boolean) {
    if (v) this.adjustIframeHeight();
  }
}
