
import HtmlRich from '@gid/vue-common/components/HtmlRich.vue';
import AxiosStream from 'axios-stream';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { LiveAgent } from '@gid/vue-common/components/ticketing/interface/ticketing';
import { mapGetters } from 'vuex';
@Component({
  components: {
    HtmlRich,
  },
  computed: {
    ...mapGetters(['access_token']),
  },
})
export default class LiveAgentAttachment extends Vue {
  @Prop({ default: () => {} }) attachment!: LiveAgent.IJsonAttachment;
  @Prop({ default: null }) id!: string;

  protected access_token!: string;

  get size(): string {
    return ((this.attachment.size || 0) / 1024).toFixed(2) + ' KB';
  }
  //left for future use as live agent don't allow downloading the attachments for now
  // download() {
  //   return AxiosStream.download(this.attachment.name, '', {
  //     method: 'get',
  //     url: `https://getitdone.ladesk.com${this.attachment.download_url}`,
  //   });
  // }
}
