
import HtmlRich from '@gid/vue-common/components/HtmlRich.vue';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import RichTextEditor from '@gid/vue-common/components/RichTextEditor.vue';
import FilesSelector from '@gid/vue-common/components/inputs/FilesSelector.vue';
import { LiveAgent } from '@gid/vue-common/components/ticketing/interface/ticketing';
import axios from 'axios';
@Component({
  components: {
    HtmlRich,
    RichTextEditor,
    FilesSelector,
  },
  computed: {
    ...mapGetters(['access_token']),
  },
})
export default class LiveAgentMessageNew extends Vue {
  @Prop({ default: null }) ticketId!: string;
  @Prop({ default: null }) isVisible!: boolean;

  senders: any = [];
  newMessage: LiveAgent.MessageInterface = {
    message: '',
    type: 'M',
    attachments: [],
  };
  useridentifier: string = '';
  showLoader: boolean = false;
  error: string = '';

  async created() {
    this.senders = await this.forSenders();
  }

  get senderOptions() {
    if (!this.senders || !this.senders.length) {
      return [];
    }
    return this.senders.map((sender: any) => {
      return { text: sender.name + ' - ' + sender.email, value: sender.email };
    });
  }

  forSenders = async () => {
    if (!this.senders || !this.senders.length) return this.fetchSenders();
    return this.senders;
  };
  async fetchSenders(): Promise<any> {
    return axios
      .get(`/data-api/ticket/live-agent/agents`)
      .then((response) => {
        this.error = '';
        return response?.data;
      })
      .catch((error) => {
        this.error = `Error fetching senders`;
        return [];
      });
  }
  attachment(files: { name?: string; data?: string }[]) {
    this.newMessage.attachments = files.map(({ name, data }) => ({
      name: name || '',
      data: data || '',
    }));
  }
  async onCreateMessage() {
    const leaveOnError = (error: string) => {
      this.error = error;
      return;
    };

    if (!this.ticketId) return leaveOnError('Die Ticket-ID ist null!');
    if (this.useridentifier === '')
      return leaveOnError('Absender muss definiert werden!');
    if (this.newMessage.message === '')
      return leaveOnError('Die Nachricht darf nicht leer sein!');

    try {
      this.showLoader = true;
      this.error = '';
      await axios.post(`/data-api/ticket-message/${this.ticketId}`, {
        ...this.newMessage,
        useridentifier: this.useridentifier,
        is_html_message: 'Y',
      });
      this.message_created();
      this.clear();
      this.close();
    } catch (error: any) {
      this.error =
        error?.response?.data?.response?.errormessage ||
        'Error occured while creating message!';
    } finally {
      this.showLoader = false;
    }
  }
  clear() {
    this.useridentifier = '';
    this.newMessage.attachments = [];
    this.newMessage.message = '';
  }
  @Emit('message-created')
  message_created() {
    return true;
  }
  @Emit('close')
  close() {
    return true;
  }
}
