
import axios from 'axios';
import { Vue, Component, Ref, Prop, Watch, Emit } from 'vue-property-decorator';
import FilesSelector from '@gid/vue-common/components/inputs/FilesSelector.vue';
import RichTextEditor from '@gid/vue-common/components/RichTextEditor.vue';
import HtmlRich from '@gid/vue-common/components/HtmlRich.vue';
import { GeneralInvoicingTargetEnum } from '@gid/models';
import { Zammad } from '@gid/vue-common/components/ticketing/interface/ticketing';
@Component({
  components: {
    FilesSelector,
    HtmlRich,
    RichTextEditor,
  },
})
export default class ArticleNew extends Vue {
  @Prop({ default: null }) article!: Zammad.ArticleInterface;
  @Prop({ default: null }) ticketId!: Number;
  @Prop({ default: null }) allTicketStates!: Object;
  @Prop({ default: null }) jobName!: String;
  @Prop({ default: false }) createButton!: boolean;
  @Prop({ default: null }) jobContacts!: Zammad.JobContact[];
  @Prop({ default: null }) jobCustomContacts!: Zammad.JobCustomContact[];
  @Prop({ default: null }) ownerZammad!: Zammad.ZammadUser;
  @Prop({ default: null }) userZammad!: Zammad.ZammadUser;
  @Prop({ default: null }) groups!: Zammad.Group[];
  @Prop({ default: null }) selectedGroup!: string;

  signature: Zammad.Signature = Zammad.blankSignature;
  articleForRole: GeneralInvoicingTargetEnum | null =
    GeneralInvoicingTargetEnum.PARTNER;
  created() {
    if (this.jobContacts && this.jobContacts.length === 1) {
      this.article.to = this.jobContacts[0].email;
      this.articleForRole = this.jobContacts[0].jobRole;
    }
    // zammad users
    if (this.articleToOptions && this.articleToOptions.length > 0) {
      this.article.to = this.articleToOptions[0].value;
    }
    if (this.groups && this.groups.length && this.selectedGroup) {
      this.onNewTicketGroup();
    }
  }
  get blankNewArticle(): Zammad.ArticleInterface {
    return {
      ...Zammad.blankArticle,
      ...{
        ticket_id: this.ticketId,
        internal: false,
        time_unit: '15',
        from: this.userEmail,
      },
    };
  }
  get articleTypeOptions(): object[] {
    return Zammad.ArticleTypeOptions(Zammad.CreationEnabledArticleTypes);
  }
  get articleTypes() {
    return [
      { id: 1, name: 'email' },
      { id: 2, name: 'sms' },
      { id: 3, name: 'chat' },
      { id: 4, name: 'fax' },
      { id: 5, name: 'phone' },
      { id: 10, name: 'note' },
    ];
  }
  get articleTypeName(): string {
    return Zammad.ArticleTypes.filter((at) => at.name === this.article.type)[0]
      ?.name;
  }
  get environment(): string {
    return Zammad.ENV();
  }
  get showAttachment(): boolean {
    return [
      Zammad.ArticleTypesEnum.EMAIL,
      Zammad.ArticleTypesEnum.NOTE,
    ].includes(this.article.type);
  }
  get showReceipient(): boolean {
    return true;
  }
  get userEmail() {
    return this.$store.state.auth.user?.contact?.email;
  }
  get parsedSignature(): string {
    return Zammad.ParsedSignature(
      this.signature,
      this.userZammad,
      this.ownerZammad,
    );
  }
  get jobRoleOptions() {
    return Object.keys(GeneralInvoicingTargetEnum).map((k) => {
      return {
        text: k,
        value: GeneralInvoicingTargetEnum[k],
      };
    });
  }
  get articleToOptionsAvailable(): boolean {
    if (
      this.jobContacts &&
      this.jobContacts !== null &&
      this.jobContacts.length > 0
    ) {
      return true;
    }
    return false;
  }
  get articleToOptions() {
    if (this.jobContacts && this.jobContacts.length > 0) {
      const allContacts = [...this.jobContacts, ...this.jobCustomContacts];

      return allContacts
        .filter((jc) => jc.jobRole === this.articleForRole)
        .map((jc) => {
          return {
            text: `${jc.email} - ${jc.lastName} ${jc.firstName} `,
            value: jc.email,
          };
        });
    }
    return [];
  }
  get showSignature(): boolean {
    return Zammad.ShowSignature(this.article.type);
  }
  attachment(files: object[]) {
    this.article.attachments = files.map(
      (f: object): Zammad.AttachmentInterface => {
        return {
          filename: f?.['name'] || '',
          data: f?.['data'] || '',
          'mime-type': f?.['mimetype'] || '',
        };
      },
    );
  }
  async createNewArticle() {
    if (this.article.type == Zammad.ArticleTypesEnum.EMAIL) {
      this.article.body += this.parsedSignature;
    }
    const res = await axios.post(
      `/zammad/api-proxy/ticket_articles`,
      this.article,
      {
        headers: { 'X-On-Behalf-Of': this.userEmail },
        maxContentLength: 50000000,
        maxBodyLength: 50000000,
      },
    );
    this.article_created();
  }
  @Watch('selectedGroup')
  @Watch('groups')
  async onNewTicketGroup() {
    if (this.groups && this.groups.length) {
      const group_sel = this.groups.filter(
        (g) => g.name == this.selectedGroup,
      )[0];
      this.signature = await Zammad.SignatureByIdAndRoleId(
        group_sel?.signature_id,
        Zammad.ZammadUserRoles(this.environment).AGENT,
      );
    }
  }

  @Emit('article-created')
  article_created() {
    return true;
  }
}
