
import axios from 'axios';
import { Vue, Component, Ref, Prop, Watch, Emit } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { RequestQueryBuilder, CondOperator } from '@dataui/crud-request';
import RichTextEditor from '@gid/vue-common/components/RichTextEditor.vue';
import HtmlRich from '@gid/vue-common/components/HtmlRich.vue';
import {
  Contact,
  JobView,
  GeneralInvoicingTargetEnum,
  JobContactPerson,
} from '@gid/models';
import ArticleNew from './ArticleNew.vue';
import { Zammad } from '@gid/vue-common/components/ticketing/interface/ticketing';
@Component({
  components: {
    ArticleNew,
    HtmlRich,
    RichTextEditor,
  },
  computed: mapGetters(['environment']),
})
export default class TicketNew extends Vue {
  @Prop({ default: null }) type!: string;
  @Prop({ default: null }) jobId!: string;
  @Prop({ default: null }) jobName!: string;
  @Prop({ default: null }) jobView!: JobView;
  @Prop({ default: null }) contacts!: Contact[];
  @Prop({ default: null }) customContacts!: JobContactPerson[];
  @Prop({ default: null }) groupPreselect!: string;
  @Prop({ default: null }) ownerPreselect!: string;
  @Prop({ default: null }) subjectPreselect!: string;
  @Prop({ default: Zammad.ArticleTypesEnum.NOTE })
  articleTypePreselect!: Zammad.ArticleTypesEnum;
  allTicketStates: object[] = [];
  showAddTicket: boolean = false;
  newTicket: Zammad.TicketInterface = this.blankNewTicket;
  groups: Zammad.Group[] = [];
  dirtyFetchedUsers: Zammad.ZammadUser[] = [];
  error: string = '';
  environment!: string;

  async created() {
    const forGroups = async () => {
      if (!this.groups || !this.groups.length) return this.fetchGroups();
      return this.groups;
    };
    const forContacts = async () => {
      if (!this.contacts || !this.contacts.length) return this.fetchContacts();
      return this.contacts;
    };
    [this.groups, this.contacts] = await Promise.all([
      forGroups(),
      forContacts(),
    ]);
    this.newTicket.group = this.groupPreselect;
    if (this.newTicket.group == null) {
      this.newTicket.group = this.groups[0]?.name;
    }
    this.newTicket.owner = this.ownerPreselect;
    this.newTicket.article.type = this.articleTypePreselect;
    this.newTicket.article.subject = this.subjectPreselect;
    this.newTicket.job_name = this.jobName;
    this.refreshZammadUsers();
  }
  get articleTypeOptions(): object[] {
    return Zammad.ArticleTypeOptions(Zammad.CreationEnabledArticleTypes);
  }
  get showSignature(): boolean {
    return Zammad.ShowSignature(this.newTicket.article.type);
  }
  nullHandler() {}
  get blankNewTicket(): Zammad.TicketInterface {
    return {
      id: 0,
      title: '',
      group: '',
      job_name: this.jobName,
      article: Zammad.blankArticle,
      owner: null,
      owner_id: null,
      group_id: null,
      created_by_id: null,
      created_at: null,
      number: null,
      customer_id: null,
    };
  }
  get locale() {
    return mapGetters(['locale']);
  }
  get jobContacts(): Zammad.JobContact[] {
    return this.contacts.map((contact) => {
      let jobRole = GeneralInvoicingTargetEnum.CUSTOMER;
      if (this.jobView) {
        if (contact.account.id == this.jobView.brand.id) {
          jobRole = GeneralInvoicingTargetEnum.BRAND;
        }
        if (contact.account.id == this.jobView.partner.id) {
          jobRole = GeneralInvoicingTargetEnum.PARTNER;
        }
      }
      return {
        ...contact,
        jobRole: jobRole,
        updateApiId: this.nullHandler,
      };
    });
  }

  get jobCustomContacts(): Zammad.JobCustomContact[] {
    return this.customContacts.map((contact) => {
      let jobRole = GeneralInvoicingTargetEnum.CUSTOMER;
      return {
        ...contact,
        jobRole,
        updateApiId: this.nullHandler,
      };
    });
  }

  get userEmail() {
    return this.$store.state.auth.user?.contact?.email;
  }
  get groupOptions() {
    if (!this.groups || !this.groups.length) {
      return [];
    }
    return this.groups.map((dfg) => {
      return { text: dfg.name, value: dfg.name };
    });
  }
  get zammadUserOptions() {
    return [
      ...this.dirtyFetchedUsers.map((dfg) => {
        return { text: dfg.email, value: dfg.email };
      }),
      {
        text: 'None',
        value: null,
      },
    ];
  }
  get zammadOwnerOptions() {
    return [
      ...this.dirtyFetchedUsers
        .filter((dfu) =>
          dfu.role_ids.includes(Zammad.ZammadUserRoles(this.environment).AGENT),
        )
        .map((dfu) => {
          return { text: dfu.email, value: dfu.email };
        }),
      {
        text: 'None',
        value: null,
      },
    ];
  }
  get userZammad(): Zammad.ZammadUser {
    return this.dirtyFetchedUsers.filter((u) => u.login == this.userEmail)[0];
  }
  get ownerZammad(): Zammad.ZammadUser {
    return this.dirtyFetchedUsers.filter(
      (u) => u.login == this.newTicket.owner,
    )[0];
  }
  async createNewTicket() {
    const leaveOnError = (error: string) => {
      this.error = error;
      return;
    };
    if (this.newTicket.group == '')
      return leaveOnError('Gruppe muss definiert sein!');
    if (
      !this.newTicket.article.to &&
      this.newTicket.article.type != Zammad.ArticleTypesEnum.NOTE
    ) {
      return leaveOnError(
        'Für email muss "TO", sprich Empfänger, gesetzt sein.',
      );
    }
    this.newTicket.customer_id = 'guess:' + this.newTicket.article.to;
    if (!this.newTicket.article.subject)
      return leaveOnError('bitte einen Betreff eingeben');
    if (this.newTicket.article.subject == undefined)
      this.newTicket.article.subject = '';
    if (
      !this.newTicket.article.body ||
      this.newTicket.article.body.length < 1
    ) {
      return leaveOnError('bitte eine Nachricht eingeben');
    }
    this.newTicket.customer_id = 'guess:' + this.newTicket.article.to;
    this.newTicket.title = this.newTicket.article.subject;
    this.newTicket.job_name = this.jobName;
    await axios
      .post(`/zammad/api-proxy/tickets`, this.newTicket, {
        headers: {
          'X-On-Behalf-Of': this.userEmail,
        },
        maxContentLength: 50000000,
        maxBodyLength: 50000000,
      })
      .then((r) => {
        this.ticket_created();
        this.clearNewTicket();
        this.error = '';
      })
      .catch((err) => {
        this.error = this.error + err.response.data;
        return this.newTicket;
      });
    // setTimeout(this.refreshTickets, 5000);
  }

  @Emit('ticket-created')
  ticket_created() {
    return true;
  }
  async refreshZammadUsers() {
    axios
      .get(`/zammad/api-proxy/users/search`, {
        params: {
          // expand: true,
          query: `role_ids:${Zammad.ZammadUserRoles(this.environment).AGENT}`,
        },
      })
      .then((response) => {
        const newUsers = response?.data;
        for (const userId in newUsers) {
          if (
            this.dirtyFetchedUsers.filter(
              (dfu) => dfu.id === newUsers[userId].id,
            ).length < 1
          ) {
            this.dirtyFetchedUsers = [
              ...this.dirtyFetchedUsers,
              newUsers[userId],
            ];
          }
        }
      });
  }
  async fetchGroups(): Promise<Zammad.Group[]> {
    return axios
      .get(`/zammad/api-proxy/groups`, {
        params: {
          expand: true,
        },
      })
      .then((response) => {
        return response?.data;
      })
      .catch(() => {
        return [];
      });
  }
  async fetchContacts(): Promise<Contact[]> {
    if (!this.jobView) return [];
    const jobInvolvedAccountIds = [
      this.jobView?.customer.id,
      this.jobView?.brand.id,
      this.jobView?.partner.id,
    ].filter((el) => el);
    const queryString = RequestQueryBuilder.create({
      filter: {
        field: 'account.id',
        operator: CondOperator.IN,
        value: jobInvolvedAccountIds,
      },
    }).query();
    return axios
      .get(`/data-api/contact?${queryString}`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  }
  clearNewTicket() {
    this.newTicket = this.blankNewTicket;
    this.showAddTicket = false;
  }
}
