<template>
  <div>
    <b-card
      bg-variant="light"
      ref="stickyFilters"
      class="my-3"
      :style="stickyStyles"
      v-if="!job_id"
    >
      <b-row>
        <b-col cols="12" lg="1">
          <b-form-group>
            <template #label>
              <span style="color: transparent">.</span>
            </template>
            <b-button block class="mr-md-3" variant="primary" to="/jobs/create">
              New
            </b-button>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="2">
          <b-form-group :label="`${$t('filter')}:`" class="mb-md-0">
            <b-input-group>
              <b-form-input
                v-model="filterSearch"
                debounce="400"
                :placeholder="$t('type-to-search')"
              />
              <b-input-group-append v-if="filterSearch">
                <b-btn @click="filterSearch = ''">&times;</b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <b-form-group :label="`${$t('status')}:`" class="mb-md-0">
            <multiselect
              v-model="filterStatus"
              :options="statuses"
              :multiple="true"
              track-by="value"
              label="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="2">
          <b-form-group :label="`${$t('owner')}:`" class="mb-md-0">
            <b-form-select v-model="filterOwner" :options="owners">
              <template slot="first">
                <option :value="null">-- {{ $t('all') }} --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="2">
          <b-form-group label="View type">
            <multiselect
              v-model="filterViewType"
              :options="['General View', 'Fulfillment View']"
              :allowEmpty="false"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="2">
          <b-form-checkbox v-model="filterTest">
            {{ $t('include-test-jobs') }}
          </b-form-checkbox>
          <b-form-checkbox
            class="pt-1"
            v-e2e:filterUseAdvancedSearch
            v-model="filterUseAdvancedSearch"
          >
            {{ $t('filters.advanced.toggle') }}
          </b-form-checkbox>
          <b-form-checkbox
            class="pt-1"
            v-e2e:filterUseQuickSearch
            v-model="filterUseQuickSearch"
          >
            {{ $t('filters.quick.toggle') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <advanced-search
        v-if="filterUseAdvancedSearch"
        v-model="filterAdvancedSearch"
        :filterOptions="advancedFilterOptions"
      />
      <div v-if="filterUseQuickSearch">
        <quick-search
          class="overflow-auto text-nowrap"
          v-model="filterQuickSearch"
          logic="and"
          :filter-combinations="filterCombinations"
        />
      </div>
    </b-card>
    <b-table
      :show-empty="!loading"
      striped
      stacked="md"
      class="gid-table-infinite"
      ref="wrapper"
      :items="items"
      :fields="tableFields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDir"
      no-local-sorting
    >
      <template #cell(partner.name)="{ item }">
        <template v-if="item.job.partner">
          <p>{{ item.partner.name }}</p>
          <p class="my-2" v-if="item.partner.name">
            <b-badge
              :variant="
                partnerExperienceLevel(item.partner.completed_jobs).variant
              "
            >
              {{ partnerExperienceLevel(item.partner.completed_jobs).text }}
            </b-badge>
          </p>
          <call-contact
            :contact-list="jobsContacts[item.api_id]"
            :role-filter="partnerRole"
            @call-initiated="createComment('partner', item.job.api_id)"
          />
        </template>
        <b-link v-else @click.prevent="$refs.partnerView.viewPartners(item)">
          {{ item.num_partners_offer_is_visible_to }}
          ({{ item.num_partners_potential }})
        </b-link>
      </template>
      <template #cell(job.name)="{ item }">
        <a
          :label="item.job.name"
          :href="`../jobs/${item.job.sfid}`"
          class="text-nowrap"
          :target="items.length > 1 ? '_blank' : '_self'"
        >
          {{ item.job.name }}
        </a>
        <b-icon-clipboard
          class="icon-grad"
          font-scale="0.8"
          @click="copyToClipboard(item.job.name)"
        />
        <small v-if="item.job.eid" class="text-muted d-block text-break">
          EID: {{ item.job.eid }}
        </small>
        <div v-if="item.job.invoicing_eid">
          <small class="text-muted">
            Inv. EID: {{ item.job.invoicing_eid }}
          </small>
        </div>
        <div v-if="item.is_invoicing_scheduled">
          <b-badge variant="info">
            {{ isJobPartiallyInvoiced(item) }}
          </b-badge>
        </div>
        <small
          v-if="item.job.affiliate_id"
          class="text-muted d-block text-break"
        >
          AID: {{ item.job.affiliate_id }}
        </small>
        <div v-if="item.productOrder">
          <b-badge :variant="item.productOrder.variant">
            {{ $t(`job.product-order-status.${item.productOrder.status}`) }}
          </b-badge>
          <small v-if="item.productOrder.shipTo.length > 0">
            Ship to:
            <b-badge
              v-for="shipTarget in item.productOrder.shipTo"
              :key="shipTarget"
            >
              {{ shipTarget }}
            </b-badge>
          </small>
          <div
            class="d-flex flex-column font-small"
            v-if="item.productOrder.trackingLink.length > 0"
          >
            <span
              v-for="(trackingLink, index) in item.productOrder.trackingLink"
              :key="trackingLink"
            >
              <b-icon-box class="mr-2" />
              <a :href="trackingLink" target="_blank">
                {{ $t(`job.tracking-link`) }} {{ index ? `(${index})` : '' }}
              </a>
            </span>
          </div>
        </div>
        <div v-if="item.product_serial_number">
          <span>
            <b-icon-upc-scan class="mr-2" />
            <span>{{ item.product_serial_number }}</span>
          </span>
        </div>
        <small v-if="Object.keys(jobRatings).length">
          <RatingWidget context="job" v-bind="jobRatings[item.job.api_id]" />
        </small>
        <div>
          <a href="#" @click.prevent="getQuotePath(item.sfid)">
            {{ $t('view-quote') }}
          </a>
        </div>
        <div v-if="item.files.customer_quote">
          <a
            :href="`/api/media/file/${item.files.customer_quote.paths[0]}?jwt=${access_token}`"
            target="_blank"
          >
            {{ $t('accepted-quote') }}
          </a>
        </div>
        <div v-if="showBrandQuoteLinks(item)">
          <a href="#" @click.prevent="getBrandQuotePath(item.sfid)">
            {{ $t('view-brand-quote') }}
          </a>
        </div>
        <div v-if="item.files.brand_quote">
          <a
            :href="`/api/media/file/${item.files.brand_quote.paths[0]}?jwt=${access_token}`"
            target="_blank"
          >
            {{ $t('accepted-brand-quote') }}
          </a>
        </div>
        <b-badge v-if="hasOwnInstaller(item)" variant="dark">
          {{ $t(`job.has-own-installer`) }}
        </b-badge>
        <div v-if="isAlternative(item)">
          <b-badge
            :style="{ background: '#50E3C2', color: 'black' }"
            :id="`alternate_quote-${item.id}`"
          >
            {{ $t(`job.is-alternative`) }}
          </b-badge>
          <b-popover
            triggers="click blur"
            :target="`alternate_quote-${item.id}`"
            title="Alternative Jobs"
          >
            <div
              v-for="(alternate, index) in getAlternativeLinks(item)"
              :key="alternate"
            >
              <a target="_blank" :href="`/jobs/${alternate}`">
                Alternative ({{ index }})
              </a>
            </div>
          </b-popover>
        </div>
        <b-badge :style="isReclamation(item).styles" v-if="isReclamation(item)">
          {{ isReclamation(item).text }}
        </b-badge>
        <b-badge
          :style="hasAutoCreatedJob(item).styles"
          v-if="hasAutoCreatedJob(item)"
        >
          {{ hasAutoCreatedJob(item).text }}
        </b-badge>
        <b-badge
          :style="hasNextStepJob(item).styles"
          v-if="hasNextStepJob(item)"
        >
          {{ hasNextStepJob(item).text }}
        </b-badge>
        <JobTypes :job="item" />
      </template>
      <template #cell(opportunity)="{ item }">
        <div>{{ item.opportunity.name }}</div>
        <div>
          <b-badge v-if="isPrepayment(item)">
            {{ $t('prepayment') }}: {{ item.opportunity.prepayment }}
          </b-badge>
        </div>
        <span
          v-if="item.project"
          class="text-muted mt-1 d-inline-block"
          style="font-size: 0.85rem; word-break: break-all"
        >
          {{ item.project.name }}
        </span>
        <call-contact
          :contact-list="jobsContacts[item.api_id]"
          :role-filter="brandRole"
          @call-initiated="createComment('brand', item.job.api_id)"
        />
      </template>
      <template #cell(job.appointment_start_timestamp)="{ item }">
        {{
          item.job.appointment_start_timestamp
            ? $moment(item.job.appointment_start_timestamp).format('L')
            : '-'
        }}
        <br />
        {{
          greaterDateFormatted(item.job.readiness_date, item.job.date_expected)
        }}
        <br />
        <strong>
          {{ latestStatusChangeFormatted(item) }}
          {{ item.status_description.gid_name[locale] }}
        </strong>
        <div v-for="(status, idx) in compoundStateItems(item)" :key="idx">
          <b-badge
            variant="warning"
            class="text-wrap"
            v-b-tooltip="status.details"
            :title="status.details"
          >
            {{ status.name }}
          </b-badge>
        </div>
        <div class="d-flex">
          <DaysSinceLastChange
            v-if="statusNotCompleted(item)"
            :days="item.days_since_last_status_change"
          />
          <DaysSinceLastChange
            v-if="item.actions_taken"
            class="ml-3"
            context="action"
            :days="item.actions_taken"
            :action_date="item.latest_action"
          />
        </div>
      </template>
      <template #cell(job._price)="{ item }">
        <job-price :job_view="item" />
      </template>
      <template #cell(properties.car_delivery)="{ item }">
        <p>
          {{
            item.properties && item.properties.car_delivery
              ? $moment(item.properties.car_delivery).format('L')
              : '-'
          }}
        </p>
        <small
          v-if="getCarTypeExist(item)"
          class="text-muted d-block text-break"
        >
          {{ $t('type') }}: {{ getCarType(item.properties.car_type) }}
        </small>
        <small
          v-if="getCarChargingTypeExist(item)"
          class="text-muted d-block text-break"
        >
          {{ $t('charging-opportunity') }}:
          {{ getChargingType(item.properties.charging_opportunity) }}
        </small>
        <p class="d-flex" v-if="shouldDisplayWallboxScore(item)">
          <DaysSinceLastChange
            v-if="item.dkv_wallbox_priority_score"
            class="ml-3"
            :days="item.dkv_wallbox_priority_score"
          />
          <span
            class="mx-2"
            v-if="getUrgentReason(item)"
            v-b-tooltip.hover
            :title="getUrgentReason(item)"
          >
            ⚠️
          </span>
        </p>
      </template>
      <template #cell(job.created_date)="{ item }">
        {{
          item.job.created_date
            ? $moment(item.job.created_date).format('L')
            : '-'
        }}
        <b-badge v-if="isDKVMissingData(item)" variant="danger">
          Missing data
        </b-badge>
        <b-badge
          v-if="item.installation && item.installation.incident"
          variant="danger"
        >
          Fehlermeldung
        </b-badge>
      </template>
      <template #cell(customer._fullname)="{ item }">
        {{ formatCustomerTitle(item.customer.title, item.customer.salutation) }}
        {{ item.customer.first_name }} {{ item.customer.last_name }}
        <div
          v-if="
            item.job_contact_person.first_name &&
            item.job_contact_person.last_name
          "
          class="mb-0"
        >
          <p class="mb-0">
            <strong>{{ $t('inputs.contact_person.title') }}:</strong>
          </p>
          <p class="my-0">
            {{ item.job_contact_person.first_name }}
            {{ item.job_contact_person.last_name }}
          </p>
          <call-contact
            :contact-list="[
              {
                phone: item.job_contact_person.phone,
                jobRole: customerRole[0],
                lastName: item.job_contact_person.last_name,
              },
            ]"
            :role-filter="customerRole"
            @call-initiated="createComment('customer', item.job.api_id)"
          />
        </div>
        <call-contact
          v-else
          :contact-list="jobsContacts[item.api_id]"
          :role-filter="customerRole"
          @call-initiated="createComment('customer', item.job.api_id)"
        />
      </template>
      <template #cell(actions)="row">
        <div class="text-nowrap">
          <b-dropdown
            right
            size="sm"
            text="Docs"
            class="mr-1"
            v-if="jobHasDocs(row.item)"
          >
            <template v-if="row.item.files.meter_application">
              <b-dropdown-item
                v-for="(protocol_url, index) in row.item.files.meter_application
                  .paths"
                :key="protocol_url"
                :href="`/api/media/file/${protocol_url}?jwt=${access_token}`"
                target="_blank"
              >
                {{ $t('job.image.meter_application', { index }) }}
              </b-dropdown-item>
            </template>
            <b-dropdown-item
              v-if="row.item.files.picture_before"
              :href="`/api/media/file/${row.item.files.picture_before.paths[0]}?jwt=${access_token}`"
              target="_blank"
            >
              {{ $t('job.image.start') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="row.item.files.picture_after"
              :href="`/api/media/file/${row.item.files.picture_after.paths[0]}?jwt=${access_token}`"
              target="_blank"
            >
              {{ $t('job.image.finish') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="row.item.files.protocol"
              :href="`/api/media/file/${row.item.files.protocol.paths[0]}?jwt=${access_token}`"
              target="_blank"
            >
              {{ $t('job.image.protocol') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="row.item.files.customer_protocol"
              :href="`/api/media/file/${row.item.files.customer_protocol.paths[0]}?jwt=${access_token}`"
              target="_blank"
            >
              {{ $t('job.image.customer_protocol') }}
            </b-dropdown-item>
            <template v-if="row.item.files.customer_signoff_feedback">
              <b-dropdown-item
                v-for="(path, index) in row.item.files.customer_signoff_feedback
                  .paths"
                :key="`feedback-${index}`"
                :href="`/api/media/file/${path}?jwt=${access_token}`"
                target="_blank"
              >
                {{ $t('job.image.customer_signoff_feedback', { index }) }}
              </b-dropdown-item>
            </template>
            <template v-if="row.item.files.customer_blueprint">
              <b-dropdown-item
                v-for="(path, index) in row.item.files.customer_blueprint.paths"
                :key="`feedback-${index}`"
                :href="`/api/media/file/${path}?jwt=${access_token}`"
                target="_blank"
              >
                {{ $t('job.image.customer_blueprint', { index }) }}
              </b-dropdown-item>
            </template>
            <template v-if="row.item.files.propagated_protocol">
              <b-dropdown-item
                v-for="(protocol_url, index) in row.item.files
                  .propagated_protocol.paths"
                :key="protocol_url"
                :href="`/api/media/file/${protocol_url}?jwt=${access_token}`"
                target="_blank"
              >
                {{ $t('job.image.propagated_protocol', { index }) }}
              </b-dropdown-item>
            </template>
            <template v-if="row.item.files.picture">
              <b-dropdown-item
                v-for="(path, index) in row.item.files.picture.paths"
                :key="`picture-${index}`"
                :href="`/api/media/file/${path}?jwt=${access_token}`"
                target="_blank"
              >
                {{ $t('job.image.picture', { index }) }}
              </b-dropdown-item>
            </template>
            <template v-if="row.item.files.planning_customer">
              <b-dropdown-item
                v-for="(path, index) in row.item.files.planning_customer.paths"
                :key="`customer-${index}`"
                :href="`/api/media/file/${path}?jwt=${access_token}`"
                target="_blank"
              >
                {{ $t('job.image.planning_customer', { index }) }}
              </b-dropdown-item>
            </template>
            <template v-if="row.item.files.planning_partner">
              <b-dropdown-item
                v-for="(path, index) in row.item.files.planning_partner.paths"
                :key="`partner-${index}`"
                :href="`/api/media/file/${path}?jwt=${access_token}`"
                target="_blank"
              >
                {{ $t('job.image.planning_partner', { index }) }}
              </b-dropdown-item>
            </template>
            <b-dropdown-item
              v-if="row.item.files.cancellation_protocol"
              :href="`/api/media/file/${row.item.files.cancellation_protocol.paths[0]}?jwt=${access_token}`"
              target="_blank"
            >
              {{ $t('job.image.cancellation_protocol') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="row.item.files.cancellation_picture"
              :href="`/api/media/file/${row.item.files.cancellation_picture.paths[0]}?jwt=${access_token}`"
              target="_blank"
            >
              {{ $t('job.image.cancellation_picture') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="row.item.files.cancellation_picture_before"
              :href="`/api/media/file/${row.item.files.cancellation_picture_before.paths[0]}?jwt=${access_token}`"
              target="_blank"
            >
              {{ $t('job.image.cancellation_picture_before') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="row.item.files.cancellation_picture_after"
              :href="`/api/media/file/${row.item.files.cancellation_picture_after.paths[0]}?jwt=${access_token}`"
              target="_blank"
            >
              {{ $t('job.image.cancellation_picture_after') }}
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            v-e2e:jobDetails
            size="sm"
            class="mr-1"
            @click.stop="row.toggleDetails"
          >
            {{
              $t(
                'table-details-toggle.' +
                  (!row.detailsShowing ? 'open' : 'close'),
              )
            }}
          </b-button>
          <b-button
            variant="light"
            size="sm"
            @click.stop="$refs.logView.showLog(row.item.job.sfid)"
          >
            Log
          </b-button>
        </div>
        <div class="mt-3">
          <b-button
            v-if="shouldDisplayInternalProtocol(row.item.job)"
            variant="primary"
            size="sm"
            @click.prevent="getInternalProtocolPath(row.item.job.sfid)"
          >
            {{ $t('job.internal-protocol') }}
          </b-button>
        </div>
      </template>
      <template #row-details="{ item }">
        <job-details
          :value="item"
          :job-contacts="jobsContacts[item.api_id]"
          @input="refreshJob"
          @comment-action="refreshJob(item)"
        />
      </template>
      <template #custom-foot="{ columns }" v-if="loading">
        <b-tr v-for="row in perPage" :key="row">
          <b-td v-for="col in columns" :key="col">
            <b-skeleton :width="`${Math.round(Math.random() * 50) + 50}%`" />
          </b-td>
        </b-tr>
      </template>
    </b-table>
    <div class="gid-results-count__holder d-flex">
      <b-button
        pill
        v-if="stickyFiltersButton"
        @click="toggleSitckyFilters"
        :variant="stickyFiltersVisible ? 'primary' : 'secondary'"
        class="mr-2"
      >
        <BIconSliders />
      </b-button>
      <div class="rounded-pill gid-results-count">
        <span :class="{ 'gid-results-count--loading': loading }">
          <template v-if="totalRows !== null">
            {{ $t('results') }}: {{ items.length }} / {{ totalRows || 0 }}
          </template>
          <BIconThreeDots v-else />
        </span>
      </div>
    </div>
    <b-alert v-if="error !== null" variant="danger" class="text-left" show>
      <h4 class="alert-heading">{{ $t('_errors.network.title') }}</h4>
      <p>{{ error.message }}</p>
      <b-collapse id="error-details" class="my-2">
        <b-card>
          <pre>{{ error.details }}</pre>
        </b-card>
      </b-collapse>
      <b-btn v-b-toggle.error-details>
        {{ $t('login.buttons.error-details') }}
      </b-btn>
    </b-alert>
    <partner-list ref="partnerView" />
    <log-list ref="logView" :statuses="statuses" :context="'admin'" />
  </div>
</template>

<script>
import axios from 'axios';
import Api from '@gid/vue-common/api';
import { mapGetters } from 'vuex';

import JobDetails from './JobDetails';
import JobPrice from './components/JobPrice';
import LogList from '@gid/vue-common/components/LogList';
import DaysSinceLastChange from '@gid/vue-common/components/DaysSinceLastChange';
import PartnerList from './components/PartnerList';

import { mapFilters } from '@gid/vue-common/store/utils';
import { SET_JOB_FILTER } from '@gid/vue-common/store/jobs.module';
import RatingWidget from '@gid/vue-common/components/RatingWidget.vue';
import { PrepaymentTypesEnum } from '@gid/models/dist/entities/opportunity-option';
import { JobStatusEnum, FormOrderModificationCategoryEnum } from '@gid/models';
import { StatusRulesNamesEnum } from '@gid/models/dist/entities/status-rules-names-enum';
import { CondOperator, RequestQueryBuilder } from '@dataui/crud-request';
import AdvancedSearch from '@gid/vue-common/components/filters/AdvancedSearch.vue';
import InfiniteScrollMixin from '@gid/vue-common/components/mixins/InfiniteScrollMixin';
import QuickSearch from '@gid/vue-common/components/filters/QuickSearch.vue';
import {
  isDKVMissingData,
  _partnerExperienceLevel,
  getPartnerExperienceRange,
} from '@gid/vue-common/utils';
import {
  JobTypeEnum,
  GeneralInvoicingTargetEnum,
  PartnerExperienceEnum,
  UserRoleEnum,
} from '@gid/models';
import { Zammad } from '@gid/vue-common/components/ticketing/interface/ticketing';
import CallContact from '@/components/CallContact.vue';
import { mapDetails } from '@gid/vue-common/components/activity/StateActivity.vue';
import {
  BIconBox,
  BIconClipboard,
  BIconUpcScan,
  BIconSliders,
  BIconThreeDots,
} from 'bootstrap-vue';
import JobTypes from './components/JobTypes.vue';

export default {
  mixins: [InfiniteScrollMixin],
  props: {
    job_id: String,
  },
  components: {
    BIconClipboard,
    JobDetails,
    JobPrice,
    LogList,
    PartnerList,
    RatingWidget,
    AdvancedSearch,
    DaysSinceLastChange,
    BIconBox,
    BIconUpcScan,
    BIconSliders,
    BIconThreeDots,
    CallContact,
    QuickSearch,
    JobTypes,
  },
  computed: {
    ...mapGetters(['locale', 'access_token']),
    ...mapFilters({
      moduleName: 'jobs',
      states: [
        'search',
        'status',
        'owner',
        'test',
        'viewType',
        'advancedSearch',
        'quickSearch',
        'useAdvancedSearch',
        'useQuickSearch',
      ],
      setMutation: SET_JOB_FILTER,
      setCallback: 'null',
    }),
    isJobPartiallyInvoiced() {
      return (item) => {
        const statusMapping = {
          invoiced: 'partial-invoiced-flag',
          scheduled: 'partial-scheduled-flag',
        };
        const statusKey = statusMapping[item?.is_invoicing_scheduled];
        return statusKey ? this.$t(statusKey) : null;
      };
    },
    partnerExperienceLevel() {
      return _partnerExperienceLevel;
    },
    copyToClipboard() {
      return async (payload) => {
        const performCopy = (_payload, withURL = false) => {
          if (_payload && typeof _payload === 'string') {
            const clipboardData = window.clipboardData || navigator.clipboard;

            let baseURL = window.location.href.substr(
              0,
              window.location.href.lastIndexOf('/'),
            );
            if (baseURL.indexOf('jobs') < 0) {
              baseURL = `${baseURL}/jobs`;
            }
            baseURL = `${baseURL}/`;
            if (!withURL) {
              baseURL = '';
            }
            const clipStore = `${baseURL}${_payload}`;
            clipboardData.writeText(clipStore);
            this.$bvToast.toast(`${clipStore} copied`, {
              title: 'Success',
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-bottom-right',
            });
          }
        };

        this.clicks++;
        if (this.clicks === 1) {
          this.timer = setTimeout(() => {
            performCopy(payload, false);
            this.clicks = 0;
          }, 300);
        } else {
          clearTimeout(this.timer);
          performCopy(payload, true);
          this.clicks = 0;
        }
      };
    },
    showBrandQuoteLinks() {
      return (item) => {
        return item.opportunity.can_approve_proposal.includes(
          UserRoleEnum.BRAND,
        );
      };
    },
    statusNotCompleted() {
      return (item) => item?.job?.status != JobStatusEnum.COMPLETED;
    },
    null() {
      return null;
    },
    getAlternativeLinks() {
      return (item) => item?.properties?.alternative_jobs;
    },
    tableFields() {
      return [
        'partner.name',
        'opportunity',
        'customer.shipping_address.postal_code',
        'customer._fullname',
        'job._price',
        'properties.car_delivery',
        'job.created_date',
        'job.appointment_start_timestamp',
        'job.name',
        'actions',
      ].map((field) => this.fieldDefinitions[field]);
    },
    partnerRole() {
      return [GeneralInvoicingTargetEnum.PARTNER];
    },
    brandRole() {
      return [GeneralInvoicingTargetEnum.BRAND];
    },
    customerRole() {
      return [GeneralInvoicingTargetEnum.CUSTOMER];
    },
    isAlternative() {
      return (job) => {
        return job?.properties?.alternative_jobs?.length;
      };
    },
    hasAutoCreatedJob() {
      return (job) => {
        if (job?.job?.auto_created_job && job?.is_installation) {
          return {
            styles: { backgroundColor: '#FC72D3' },
            text: this.$t('job.has-auto-job-precheck'),
          };
        } else if (
          job?.job?.auto_created_job &&
          job?.is_activation &&
          job?.job?.status === JobStatusEnum.NEW
        ) {
          return {
            styles: { backgroundColor: '#C1E609', color: 'black' },
            text: this.$t('job.has-auto-job-installation'),
          };
        }
      };
    },
    hasNextStepJob() {
      return (job) => {
        if (job?.job?.next_step_job && job?.is_precheck) {
          return {
            styles: { backgroundColor: '#FC72D3' },
            text: this.$t('job.has-next-step-job-precheck'),
          };
        } else if (job?.job?.next_step_job && job?.is_installation) {
          return {
            styles: { backgroundColor: '#C1E609', color: 'black' },
            text: this.$t('job.has-next-step-job-installation'),
          };
        }
      };
    },
    hasOwnInstaller() {
      return (job) => {
        return job?.job?.own_installer;
      };
    },
    isReclamation() {
      return (job) => {
        if (job?.job?.is_reclamation) {
          return {
            styles: { backgroundColor: '#E9692C' },
            text: this.$t('job.is-reclamation'),
          };
        }
      };
    },
    shouldDisplayInternalProtocol() {
      return (job) => {
        return [
          JobStatusEnum.APPROVAL_PENDING,
          JobStatusEnum.COMPLETED,
          JobStatusEnum.INVOICE_SENT,
          JobStatusEnum.FINISHED,
        ].includes(job?.status);
      };
    },
    shouldDisplayWallboxScore() {
      return (item) => {
        const notInStatuses = [
          JobStatusEnum.CLOSED,
          JobStatusEnum.APPROVAL_PENDING,
          JobStatusEnum.FINISHED,
          JobStatusEnum.CANCELLED,
          JobStatusEnum.INVOICE_SENT,
          JobStatusEnum.COMPLETED,
        ];
        if (
          item?.productOrder &&
          item.productOrder.status != 'all' &&
          !notInStatuses.includes(item.job.status)
        ) {
          if (!item?.is_precheck && !item?.is_activation) {
            return true;
          }
        }

        return false;
      };
    },
    getCarTypeExist() {
      return (item) => item.properties?.car_type?.length;
    },
    getCarChargingTypeExist() {
      return (item) => item.properties?.charging_opportunity?.length;
    },
    getUrgentReason() {
      return (item) => item.properties?.urgent_reason;
    },
    getChargingType() {
      return (charging_possibility) => {
        let humanizedType = '';
        if (charging_possibility == 'Ja') {
          humanizedType = this.$t('yes');
        }
        if (charging_possibility == 'Nein') {
          humanizedType = this.$t('no');
        }
        return humanizedType;
      };
    },
    getCarType() {
      return (car_type) => {
        let humanizedType = '';
        if (car_type == 'Voll elektrisches Auto')
          humanizedType = this.$t('car_type.electric');
        if (car_type == 'Hybrid-Auto')
          humanizedType = this.$t('car_type.hybrid');
        return humanizedType;
      };
    },
  },
  data() {
    return {
      clicks: 0,
      timer: {},
      jobRatings: {},
      jobsContacts: {},
      fieldDefinitions: {
        opportunity: {
          label: this.$t('job.table.heading.opportunity'),
          key: 'opportunity',
        },
        'customer.shipping_address.postal_code': {
          label: this.$t('job.table.heading.zip'),
          key: 'customer.shipping_address.postal_code',
        },
        'customer._fullname': {
          label: this.$t('job.table.heading.customer'),
          key: 'customer._fullname',
        },
        'job._price': {
          label: this.$t('job.table.heading.price'),
          key: 'job._price',
          class: 'text-right',
        },
        'job.name': {
          label: this.$t('job.table.heading.job'),
          key: 'job.name',
        },
        actions: {
          label: this.$t('job.table.heading.actions'),
          key: 'actions',
        },
        'partner.name': {
          label: this.$t('job.table.heading.partner'),
          key: 'partner.name',
          sortable: true,
        },
        'job.created_date': {
          label: this.$t('job.table.heading.created_date'),
          key: 'job.created_date',
          sortable: true,
        },
        'job.appointment_start_timestamp': {
          label: this.$t('job.table.heading.appointment'),
          key: 'job.appointment_start_timestamp',
          sortable: true,
          formatter: (value) => (value ? this.$moment(value).format('L') : '-'),
        },
        'properties.car_delivery': {
          label: this.$t('job.table.heading.car_delivery'),
          key: 'properties.car_delivery',
          sortable: true,
        },
      },
      advancedFilterOptions: [
        {
          label: this.$i18n.t('filters.created-date'),
          type: 'createddate',
          component: 'from-to-filter',
        },
        {
          label: this.$i18n.t('filters.installation-date'),
          type: 'appointment_date',
          component: 'from-to-filter',
        },
        {
          label: this.$i18n.t('filters.completed-date'),
          type: 'completed_date',
          component: 'from-to-filter',
        },
        {
          label: this.$i18n.t('filters.car_delivery'),
          type: 'car_delivery_date',
          component: 'from-to-filter',
        },
        {
          label: 'Car type',
          type: 'car_type',
          component: 'filter-multiselect',
          values: [
            { text: 'Voll elektrisches Auto', value: 'electric' },
            { text: 'Hybrid-Auto', value: 'hybrid' },
          ],
        },
        {
          label: 'Charging possibility',
          type: 'charging_opportunity',
          component: 'filter-multiselect',
          values: [
            { text: 'Ja', value: 'yes' },
            { text: 'Nein', value: 'no' },
          ],
        },
        {
          label: 'Override reason',
          type: 'urgent_reasons',
          component: 'filter-multiselect',
          values: [
            { text: 'Dringend vorzuziehen', value: 'urgent' },
            { text: 'Nein', value: 'no' },
          ],
        },
        {
          label: this.$i18n.t('filters.external-object-id'),
          type: 'eid',
          component: 'b-form-input',
        },
        {
          label: this.$i18n.t('filters.external-invoicing-id'),
          type: 'invoicing_eid',
          component: 'b-form-input',
        },
        {
          label: this.$i18n.t('filters.external-partner-eid'),
          type: 'partner_eid',
          component: 'b-form-input',
        },
        {
          label: this.$i18n.t('filters.job-type'),
          type: 'job_type',
          component: 'filter-multiselect',
          values: [
            { text: 'Precheck', value: JobTypeEnum.PRECHECK },
            { text: 'Installation', value: JobTypeEnum.INSTALLATION },
            { text: 'Activation', value: JobTypeEnum.ACTIVATION },
            { text: 'Hardware', value: JobTypeEnum.HARDWARE_SALE },
            { text: 'Remote', value: JobTypeEnum.REMOTE },
            {
              text: 'Defect Elimination',
              value: JobTypeEnum.DEFECT_ELIMINATION,
            },
            { text: 'Maintenance', value: JobTypeEnum.MAINTENANCE },
            { text: 'Meter Application', value: JobTypeEnum.METER_APPLICATION },
            {
              text: 'Installation Preparation',
              value: JobTypeEnum.INSTALLATION_PREPARATION,
            },
            {
              text: 'Reclamation',
              value: 'reclamation',
            },
          ],
        },
        {
          label: this.$i18n.t('inputs.headings.affiliate_id'),
          type: 'affiliate_id',
          component: 'CrudMultiSelect',
          endpoint: '/data-api/affiliate-id',
          searchFields: ['id', 'affiliateId'],
          trackBy: 'affiliateId',
          selectLabel: 'affiliateId',
          multiple: true,
          initFetchLabels: true,
        },
        {
          label: this.$i18n.t('filters.partner'),
          type: 'service_partner',
          component: 'CrudMultiSelect',
          endpoint: '/data-api/partner',
          searchFields: ['id', 'name'],
          trackBy: 'id',
          selectLabel: 'name',
          multiple: true,
          initFetchLabels: true,
        },
        {
          label: this.$i18n.t('filters.opportunity'),
          type: 'opportunity',
          component: 'CrudMultiSelect',
          endpoint: '/data-api/opportunity',
          searchFields: ['id', 'name'],
          trackBy: 'id',
          selectLabel: 'name',
          multiple: true,
          initFetchLabels: true,
        },
        {
          label: this.$i18n.t('filters.product'),
          type: 'products',
          component: 'CrudMultiSelect',
          endpoint: '/data-api/product',
          searchFields: ['name'],
          trackBy: 'id',
          selectLabel: 'name',
          multiple: true,
          initFetchLabels: true,
        },
        {
          label: this.$i18n.t('filters.tracking-id'),
          type: 'tracking_id',
          component: 'b-form-input',
        },
        {
          label: this.$i18n.t('filters.product-serial-number'),
          type: 'product_serial_number',
          component: 'b-form-input',
        },
        {
          label: this.$i18n.t('filters.brand'),
          type: 'brand',
          component: 'CrudMultiSelect',
          endpoint: '/data-api/brand',
          searchFields: ['id', 'name'],
          trackBy: 'id',
          selectLabel: 'name',
          multiple: true,
          valueOfinitFetchLabels: true,
        },
        {
          label: this.$i18n.t('filters.negative-margin'),
          type: 'negative_margin',
          component: 'b-form-checkbox',
        },
        {
          label: this.$i18n.t('filters.partially-invoiced'),
          type: 'partially_invoicing_scheduled',
          component: 'b-form-checkbox',
        },
        {
          label: this.$i18n.t('filters.automatically-completed'),
          type: 'automatically_completed',
          component: 'b-form-checkbox',
        },
        {
          label: this.$i18n.t('filters.payment-reminder-blocked'),
          type: 'payment_reminder_blocker',
          component: 'b-form-checkbox',
        },
        {
          label: this.$i18n.t('filters.invoices-sent'),
          type: 'invoices_sent',
          component: 'b-form-checkbox',
        },
        {
          label: this.$i18n.t('filters.automatically-created'),
          type: 'automatically-created',
          component: 'b-form-checkbox',
        },
        {
          label: this.$i18n.t('filters.service'),
          type: 'service',
          component: 'CrudMultiSelect',
          endpoint: '/data-api/service',
          searchFields: ['id', 'name'],
          trackBy: 'id',
          selectLabel: 'descriptionDe',
          customLabel: (item) => {
            return `[${item.opportunity.name}] ${item.descriptionDe}`;
          },
          multiple: true,
          initFetchLabels: true,
        },
        {
          label: this.$i18n.t('filters.state'),
          type: 'state',
          component: 'filter-multiselect',
          values: [
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.MISSING_INVOICING_EID}.missing`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.MISSING_EID}.missing`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_PREWORK}.awaiting`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.HOME_ELECTRICIAN_NOT_REACHABLE}.unreachable`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_NOT_REACHABLE}.unreachable`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_WAITING_PRODUCT}.awaiting`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_WAITING_SUBSIDY}.awaiting`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_PAUSE_GENERIC}.paused`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PRODUCT_ORDERING}.pending-order`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PRODUCT_ORDERING}.pending-delivery`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.BRAND_PRODUCT_ORDERING}.waiting`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PARTNER_INFO}.awaiting`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PARTNER_PAUSE_GENERIC}.paused`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.BRAND_PAUSE_GENERIC}.paused`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.PARTNER_ONBOARDING}.pending`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.ADMIN_VALIDATION}.pending`,
            `${JobStatusEnum.ON_HOLD}.${StatusRulesNamesEnum.CUSTOMER_SIGNATURE}.missing-customer`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.PARTNER_QUOTE_VALIDATION}.pending-validation`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.NETWORK_OPERATOR}.waiting`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.AGREEMENT_OWNER}.waiting`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.CUSTOMER_PREWORK_BEFORE_ACCEPTED}.waiting`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.CUSTOMER_INFO}.waiting`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.CUSTOMER_INFO_GENERIC}.waiting`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.BRAND_INFO_GENERIC}.waiting`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.PARTNER_INFO_GENERIC}.waiting`,
            `${JobStatusEnum.ON_HOLD_BEFORE_ACCEPTED}.${StatusRulesNamesEnum.ADMIN_VALIDATION}.pending`,
          ].map((value) => ({
            text: this.$t(`state.${value}`),
            value,
          })),
        },
        {
          label: this.$i18n.t('filters.partner-experience'),
          type: 'partner-experience',
          component: 'filter-multiselect',
          values: Object.values(PartnerExperienceEnum).map((value) => {
            const [lowerRange, upperRange] = getPartnerExperienceRange(value);
            return {
              text: value,
              value: JSON.stringify({
                [`partner-experience_from`]: lowerRange,
                [`partner-experience_to`]: upperRange,
              }),
            };
          }),
          multiple: false,
        },
      ],
      filterCombinations: [
        {
          text: this.$t('filters.precheck'),
          type: 'job_type',
          value: JobTypeEnum.PRECHECK,
        },
        {
          text: this.$t('filters.remote-precheck'),
          type: 'job_type',
          value: 'rpr',
        },
        {
          text: this.$t('filters.onsite-precheck'),
          type: 'job_type',
          value: 'onsitepr',
        },
        {
          text: this.$t('filters.installation'),
          type: 'job_type',
          value: JobTypeEnum.INSTALLATION,
        },
        {
          text: this.$t('filters.hardware'),
          type: 'job_type',
          value: JobTypeEnum.HARDWARE_SALE,
        },
        {
          text: this.$t('filters.activation'),
          type: 'job_type',
          value: JobTypeEnum.ACTIVATION,
        },
        {
          text: this.$t('remote-job'),
          type: 'job_type',
          value: JobTypeEnum.REMOTE,
        },
        {
          text: this.$t('filters.maintenance'),
          type: 'job_type',
          value: JobTypeEnum.MAINTENANCE,
        },
        {
          text: this.$t('filters.defect-elimination'),
          type: 'job_type',
          value: JobTypeEnum.DEFECT_ELIMINATION,
        },
        {
          text: this.$t('filters.reclamation'),
          type: 'job_type',
          value: 'reclamation',
        },
      ],
      perPage: 10,
      totalRows: null,
      sortBy: null,
      sortDir: false,
      statuses: [],
      owners: [],
      error: null,
      productOrderStatus: {},
      items: [],
      loading: 0,
    };
  },
  methods: {
    formatCustomerTitle(title, salutation) {
      const formatedTitle = (title?.split(' ') || []).map((t) =>
        t.toLowerCase(),
      );
      const formatedSalutation = (salutation?.split(' ') || []).map((t) =>
        t.toLowerCase(),
      );
      const combined = [...new Set([...formatedTitle, ...formatedSalutation])]
        .filter((t) => t !== 'herr' && t !== 'frau')
        .map((t) => t.charAt(0).toUpperCase() + t.slice(1));
      return combined.join(' ');
    },
    async getInternalProtocolPath(job_id) {
      const internalProcotolTab = window.open('', '_blank');
      internalProcotolTab.document.title = 'Loading Internal Protocol...';
      const resp = await axios.get(
        `/api/admin/jobs/${job_id}/internal-protocol`,
      );
      const path = `/api/media/file/${resp.data}?jwt=${this.access_token}`;
      internalProcotolTab.location.href = path;
    },
    async getQuotePath(job_id) {
      const resp = await axios.get(`/api/admin/jobs/${job_id}/quote`);
      const path = `/api/media/file/${resp.data}?jwt=${this.access_token}`;
      window.open(path, '_blank').focus();
    },
    async getBrandQuotePath(job_id) {
      const resp = await axios.get(`/api/admin/jobs/${job_id}/brand-quote`);
      const path = `/api/media/file/${resp.data}?jwt=${this.access_token}`;
      window.open(path, '_blank').focus();
    },
    createComment(target, jobApiId) {
      let message = '';
      let visibleForRoles = [];
      switch (target) {
        case 'brand': {
          message = this.$t('call.brand');
          visibleForRoles = [UserRoleEnum.ADMIN, UserRoleEnum.BRAND];
          break;
        }
        case 'customer': {
          message = this.$t('call.customer');
          visibleForRoles = [UserRoleEnum.ADMIN, UserRoleEnum.CUSTOMER];
          break;
        }
        case 'partner': {
          message = this.$t('call.partner');
          visibleForRoles = [UserRoleEnum.ADMIN, UserRoleEnum.BRAND];
          break;
        }
      }
      const commentData = {
        actionType: 'phone',
        jobApiId,
        message,
        visibleForRoles: visibleForRoles,
      };
      axios.post(`/data-api/comment`, commentData);
    },
    async refreshJob(job_view) {
      try {
        const { data: job } = await axios.get(
          `/api/admin/jobs/${job_view.job.sfid}`,
        );
        this.jobUpdated(job);
      } catch (error) {
        this.$bvToast.toast(
          error.response?.status || this.$t('_errors.network.details'),
          {
            title: this.$t('_errors.server.title'),
            variant: 'danger',
          },
        );
      }
    },
    jobUpdated(newJob) {
      this.items = this.items.map((item) => {
        if (item.job.sfid == newJob.job.sfid) {
          return {
            ...newJob,
            _showDetails: true,
          };
        } else {
          return item;
        }
      });
    },
    async loadData(append = false) {
      if (!append) {
        this.totalRows = null;
        this.items = [];
      }
      if (this.totalRows !== null && this.items.length >= this.totalRows) {
        return;
      }
      this.loading = (this.totalRows ?? this.perPage) - this.items.length;
      if (this.loading > this.perPage) {
        this.loading = this.perPage;
      }
      let sort_by = this.sortBy ? this.sortBy : null;
      if (
        this.filterViewType === 'Fulfillment View' &&
        sort_by === 'job.appointment_start_timestamp'
      ) {
        sort_by = 'priority';
      }

      let params = {
        use_table_fields: true,
        page_size: this.perPage,
        page_number: 0,
        search: this.filterSearch.trim(),
        status: this.filterStatus?.map((s) => s.value),
        owner: this.filterOwner,
        include_test_jobs: this.filterTest,
        sort_by,
        sort_dir: this.sortDir ? 'desc' : 'asc',
        excluding: this.items.map(({ api_id }) => api_id),
      };
      if (this.filterUseAdvancedSearch || this.filterUseQuickSearch) {
        let filters = {};
        if (this.filterUseAdvancedSearch) {
          filters = {
            ...JSON.parse(JSON.stringify(this.filterAdvancedSearch)),
          };
        }
        if (this.filterUseQuickSearch) {
          const logic = Object.keys(filters)[0];
          if (this.filterQuickSearch[logic]) {
            filters[logic].push(...this.filterQuickSearch[logic]);
          } else {
            filters = this.filterQuickSearch;
          }
        }
        params.search_json = JSON.stringify(filters);
      }
      if (this.job_id) {
        params.search = this.job_id;
        params.status = null;
        params.owner = null;
        params.include_test_jobs = true;
        params.search_json = undefined;
      }

      Api.post(`/api/admin/jobs`, params, {
        autoCancelScope: this,
      })
        .then((response) => {
          this.error = null;
          this.totalRows =
            this.items.length + response.data.pagination.total_count;
          this.items.push(...response.data.data);
        })
        .catch((error) => {
          const err = { details: error };
          if (error.response) {
            err.message = error.response.data.msg || error.response.data;
          } else if (error.request) {
            err.message = this.$t('_errors.network.details');
          } else {
            err.message = error.message;
          }
          this.error = err;
        })
        .finally(() => {
          this.loading = 0;
        });
    },
    greaterDateFormatted(a, b) {
      const date = a && b ? (a > b ? a : b) : a ? a : b;
      return date
        ? this.$t('job.readyforinstallation', {
            installationdate: this.$moment(date).format('L'),
          })
        : '-';
    },
    latestStatusChangeFormatted(item) {
      return this.$t('job.statuschange', {
        status_change_date: this.$moment(
          item.job.last_status_change ?? item.job.created_date,
        ).format('L'),
      });
    },
    jobHasDocs(job_view) {
      return Object.keys(job_view.files).length > 0;
    },
    isPrepayment(job_view) {
      return [
        PrepaymentTypesEnum.REQUIRED,
        PrepaymentTypesEnum.OPTIONAL,
      ].includes(job_view.opportunity.prepayment);
    },
    isDKVMissingData(job_view) {
      return isDKVMissingData(job_view);
    },
    hasInstallationIncidentRaisedByPartner(job_view) {
      return (
        job_view.is_installation &&
        job_view.has_installation_incident_raised_by_partner
      );
    },
    async loadJobRatings(items) {
      try {
        const response = await axios.post(
          '/rating-api/averages/for-admin/job',
          {
            ids: items.map((entry) => entry.api_id),
          },
        );
        this.jobRatings = response.data;
      } catch {
        // Ignore error
      }
    },
    async loadJobsContacts(items) {
      const allAccounts = items.reduce((all, i) => {
        return [...all, ...[i.brand?.id, i.customer?.id, i.partner?.id]];
      }, []);
      const contacts = await Zammad.fetchContacts(allAccounts);
      this.jobsContacts = items.reduce((all, i) => {
        return { ...all, ...{ [i.api_id]: Zammad.jobContacts(contacts, i) } };
      }, {});
    },
    async loadInstallationIncidentFlags() {
      const jobs = this.items.filter((job_view) => job_view.is_installation);
      if (jobs.length == 0) {
        return;
      }
      Promise.all(
        jobs.map((job) => {
          return axios
            .get(
              `/order-intake-forms-api/form/has-incident/${job.api_id}/category/${FormOrderModificationCategoryEnum.DOCUMENTATION}`,
            )
            .then(({ data }) => {
              const itemIndex = this.items.findIndex(
                (job_view) => job_view.api_id === job.api_id,
              );
              this.items[itemIndex]['installation'] = {
                incident: data.incident,
              };
            });
        }),
      );
    },
    async loadNumPartnersOfferIsVisibleTo() {
      const jobIds = this.items
        .filter(
          (job_view) =>
            [
              JobStatusEnum.NEW,
              JobStatusEnum.PROPOSAL_APPROVAL_PENDING,
              JobStatusEnum.OPEN,
              JobStatusEnum.ACCEPTED,
            ].includes(job_view.job.status) &&
            !job_view.num_partners_offer_is_visible_to,
        )
        .map(({ job }) => job.sfid);
      if (jobIds.length < 1) {
        return;
      }
      Promise.all(
        jobIds.map((job_sfid) => {
          return axios
            .get(`/api/admin/jobs/${job_sfid}/num_partners_offer_is_visible_to`)
            .then(({ data }) => {
              const itemIndex = this.items.findIndex(
                (job_view) => job_view.job.sfid === job_sfid,
              );
              this.items[itemIndex]['num_partners_offer_is_visible_to'] =
                data.num_partners_offer_is_visible_to;
              this.items[itemIndex]['num_partners_potential'] =
                data.num_partners_potential;
            });
        }),
      );
    },
    async loadProductOrders(items) {
      this.productOrderStatus = {};
      const jobIds = items.map(({ sfid }) => sfid);
      if (jobIds.length == 0) {
        return;
      }
      const pendingQuery = RequestQueryBuilder.create({
        search: {
          'JobView.id': { [CondOperator.IN]: jobIds },
        },
      }).query();
      const orderedQuery = RequestQueryBuilder.create({
        search: {
          'job.id': { [CondOperator.IN]: jobIds },
        },
      }).query();
      let pending, ordered;
      try {
        pending = await axios
          .get(`/documents-api/product-orders-pending?${pendingQuery}`)
          .then(({ data }) => data);
        ordered = await axios
          .get(`/documents-api/product-orders?${orderedQuery}`)
          .then(({ data }) => data);
      } catch {
        return;
      }
      this.items.forEach((job) => {
        const pendingOrder = pending.filter(
          (order) => order.job.id == job.sfid,
        );
        const orderedOrders = ordered.filter(
          (order) => order.job.id == job.sfid,
        );
        if (pendingOrder.length || orderedOrders.length) {
          let info = {};
          if (orderedOrders.length == 0) {
            info = { status: 'none', variant: 'danger' };
          } else if (pendingOrder.length == 0) {
            info = { status: 'all', variant: 'success' };
          } else {
            info = { status: 'some', variant: 'warning' };
          }

          info.orderDetails = [
            ...new Set(
              orderedOrders
                ?.sort(
                  (firstEl, secondEl) =>
                    secondEl.created_at - firstEl.created_at,
                )
                .map((order) => ({
                  items: order.items.map(({ productName }) => productName),
                  url: order?.carrier?.urlPattern?.replace(
                    '{ID}',
                    order.trackingID,
                  ),
                  trackingID: order.trackingID,
                  shipTo: order.shipTo,
                })),
            ),
          ];
          info.trackingLink = [
            ...new Set(
              orderedOrders
                .sort(
                  (firstEl, secondEl) =>
                    secondEl.created_at - firstEl.created_at,
                )
                .map((order) =>
                  order?.carrier?.urlPattern?.replace('{ID}', order.trackingID),
                ),
            ),
          ];
          info.shipTo = [
            ...new Set(
              orderedOrders
                .filter(({ shipTo }) => shipTo !== null || shipTo !== undefined)
                .map(({ shipTo }) => shipTo),
            ),
          ];
          this.$set(job, 'productOrder', info);
        }
      });
    },
    compoundStateItems(job_view) {
      return mapDetails.call(
        this,
        job_view.job.states?.list?.[0]?.startsWith(job_view.job.status) &&
          job_view.job.states?.list?.[0].includes('.')
          ? job_view.job.states?.list
          : [],
        job_view.job.states?.details,
      );
    },
  },
  watch: {
    filterUseAdvancedSearch(nextVal, prevVal) {
      const thisPrev = JSON.stringify(this.filterAdvancedSearch);
      if (prevVal && this.filterAdvancedSearch) {
        Object.keys(this.filterAdvancedSearch).forEach((key) => {
          this.filterAdvancedSearch[key] = [];
        });
      }
      const thisNext = JSON.stringify(this.filterAdvancedSearch);
      if (thisPrev !== thisNext) {
        this.$nextTick(() => this.loadData());
      }
    },
    filterUseQuickSearch(nextVal, prevVal) {
      const thisPrev = JSON.stringify(this.filterQuickSearch);
      if (prevVal && this.filterQuickSearch) {
        Object.keys(this.filterQuickSearch).forEach((key) => {
          this.filterQuickSearch[key] = [];
        });
      }
      const thisNext = JSON.stringify(this.filterQuickSearch);
      if (thisPrev !== thisNext) {
        this.$nextTick(() => this.loadData());
      }
    },
    items(items) {
      if (items.length === 1) {
        this.items[0]._showDetails = true;
        this.items[0].show = true;
      }
      this.loadJobRatings(items);
      this.loadProductOrders(items);
      this.loadJobsContacts(items);
      this.loadInstallationIncidentFlags();
      this.loadNumPartnersOfferIsVisibleTo();
    },
    sortBy() {
      this.loadData();
    },
    sortDir() {
      this.loadData();
    },
    filterSearch(nextVal, prevVal) {
      if (prevVal.trim() !== nextVal.trim()) {
        this.$nextTick(() => this.loadData());
      }
    },
    filterAdvancedSearch(nextVal, prevVal) {
      if (JSON.stringify(prevVal) !== JSON.stringify(nextVal)) {
        this.$nextTick(() => this.loadData());
      }
    },
    filterQuickSearch(nextVal, prevVal) {
      if (JSON.stringify(prevVal) !== JSON.stringify(nextVal)) {
        this.$nextTick(() => this.loadData());
      }
    },
    filterOwner(nextVal, prevVal) {
      if (prevVal !== nextVal) {
        this.$nextTick(() => this.loadData());
      }
    },
    filterStatus(nextVal, prevVal) {
      if (JSON.stringify(prevVal) !== JSON.stringify(nextVal)) {
        this.$nextTick(() => this.loadData());
      }
    },
    filterTest(nextVal, prevVal) {
      if (prevVal !== nextVal) {
        this.$nextTick(() => this.loadData());
      }
    },
    filterViewType(nextVal, prevVal) {
      if (prevVal !== nextVal) {
        this.$nextTick(() => this.loadData());
      }
    },
  },
  created() {
    this.loadData();
    axios.get(`/api/admin/jobs/statuses`).then((response) => {
      this.statuses = response.data.map((status) => ({
        text: status.gid_name[this.locale],
        value: status.job_status,
      }));
    });
    axios.get(`/api/admin/owners/fulfillment`).then((response) => {
      this.owners = response.data.map((item) => ({
        value: item.sfid,
        text: item.name,
      }));
    });
  },
};
</script>
<style lang="scss" scoped>
.icon-grad {
  background: #04a789;
  background: linear-gradient(
    49deg,
    rgba(2, 51, 36, 0.78) 0%,
    rgba(4, 167, 137, 1) 100%
  );
  border: 0px;
  color: white;
  transition: 0.9s;
  opacity: 0.4;
}
.icon-grad:hover {
  background: linear-gradient(
    49deg,
    rgba(2, 0, 36, 0.8) 0%,
    rgba(4, 167, 137, 1) 100%
  );
  opacity: 1;
}
</style>
