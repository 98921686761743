<template>
  <div>
    <b-button
      class="m-md-2 b-secondary"
      style="margin: 0"
      @click="showPartnerCallModal = true"
    >
      <span class="material-icons text-decoration-none text-white p-2 rounded">
        phone
      </span>
    </b-button>
    <CallContactModal
      :visible="showPartnerCallModal"
      :contactList="filteredContactList"
      @hide="showPartnerCallModal = false"
      @call-initiated="onCallInitiated"
    />
  </div>
</template>

<script>
import CallContactModal from '@/components/CallContactModal.vue';

export default {
  components: {
    CallContactModal,
  },
  props: {
    contactList: {
      type: Array,
      default: () => [],
    },
    roleFilter: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showPartnerCallModal: false,
    };
  },
  computed: {
    filteredContactList() {
      return this.contactList
        .map((jc) => {
          const phoneRegex = /[+,\d]/g;
          let phone = null;

          if (jc.mobilePhone && phoneRegex.test(jc.mobilePhone)) {
            phone = jc.mobilePhone;
          } else if (jc.phone && phoneRegex.test(jc.phone)) {
            phone = jc.phone;
          }

          return {
            ...jc,
            phone,
          };
        })
        .filter(
          (jc) =>
            jc.jobRole &&
            jc.phone &&
            (!this.roleFilter || this.roleFilter.includes(jc.jobRole)),
        )
        .sort((a, b) => (a.lastName || '').localeCompare(b.lastName || ''));
    },
  },
  methods: {
    onCallInitiated(selectedPhone) {
      if (selectedPhone) {
        window.open(`tel:${selectedPhone}`);
      }
      this.$emit('call-initiated');
    },
  },
};
</script>
