
import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator';
import { FetchTickets } from '../../../components/ticketing/TicketsList.vue';
import { Zammad } from '@gid/vue-common/components/ticketing/interface/ticketing';
import axios from 'axios';
import { Contact } from '@gid/models';
import { RequestQueryBuilder, CondOperator } from '@dataui/crud-request';
import PartnerListItem from './PartnerListItem.vue';

interface TicketWithEmail {
  ticket: Zammad.TicketInterface;
  email: string;
}

@Component({
  components: {
    PartnerListItem,
  },
})
export default class PartnerList extends Vue {
  jobName: string | undefined = '';
  partners: object[] = [];
  partnersPotential: object[] = [];
  partnerContacts: Contact[] = [];
  jobApiId: string = '';
  jobSfid: string = '';
  limit: number = 10;
  $refs!: {
    partnersModal: HTMLFormElement;
  };
  rejectionReasons: string[] = [];
  async created() {
    this.rejectionReasons = await this.fetchRejectionReasons();
  }
  get limitOptions() {
    return [5, 10, 25, 50, 100];
  }
  @Watch('limit', {
    deep: true,
  })
  searchChange() {
    this.refreshPartnerList();
  }
  async viewPartners(item) {
    this.jobName = item.job.name;
    this.jobApiId = item.job.api_id;
    this.jobSfid = item.job.sfid;
    this.$refs.partnersModal.show();
    this.partners = [];
    this.partnersPotential = [];
    this.refreshPartnerList();
  }
  async refreshPartnerList() {
    const qualifiedForJobUrl = `/api/admin/partners/qualified-for-job/${this.jobSfid}`;
    const [partners, partnersPotential] = await Promise.all([
      axios
        .get(qualifiedForJobUrl, {
          params: {
            in_radius: true,
            limit: this.limit,
          },
        })
        .then((response) => {
          return response.data;
        }),

      axios
        .get(qualifiedForJobUrl, {
          params: {
            only_potential: true,
            in_radius: true,
            limit: this.limit,
          },
        })
        .then((response) => {
          return response.data;
        }),
    ]);
    const accountIds = [...partners, ...partnersPotential]
      .map((p) => {
        return p.partner_id;
      })
      .filter((el) => el);
    const partnerContacts = await this.fetchContacts(accountIds);

    const matchPartnerWithContacts = (partners, contacts) => {
      return partners.map((p) => {
        const thisPartnerContacts = contacts.filter(
          (pc) =>
            pc.accountId === p.partner_id &&
            (pc.mobilePhone || pc.phone) &&
            pc.email,
        );
        let pickedPartnerContacts: Contact[] | object = [];
        if (thisPartnerContacts.length) {
          pickedPartnerContacts = thisPartnerContacts;
        }
        return {
          ...p,
          ...{
            contacts: pickedPartnerContacts,
          },
        };
      });
    };
    const partnersWithContacts = matchPartnerWithContacts(
      partners,
      partnerContacts,
    );
    const partnersPotentialWithContacts = matchPartnerWithContacts(
      partnersPotential,
      partnerContacts,
    );
    this.refreshTickets(partnersWithContacts, partnersPotentialWithContacts);
  }
  async delayedRefreshTickets() {
    setTimeout(this.refreshTickets, 5000);
  }
  async refreshTickets(partnersWithContacts, partnersPotentialWithContacts) {
    const [ticketsWithEmailForActive, ticketsWithEmailForPotential] =
      await Promise.all([
        this.fetchAllOnboardingTickets(partnersWithContacts, this.jobName),
        this.fetchAllOnboardingTickets(partnersPotentialWithContacts),
      ]);
    this.partners = this.matchPartnerWithTickets(
      partnersWithContacts,
      ticketsWithEmailForActive,
    );
    this.partnersPotential = this.matchPartnerWithTickets(
      partnersPotentialWithContacts,
      ticketsWithEmailForPotential,
    );
  }
  async fetchAllOnboardingTickets(
    partners,
    jobNameToFilter: string = '',
  ): Promise<TicketWithEmail[]> {
    let queryBase = 'group.name:Partner_Onboarding AND (';
    if (jobNameToFilter.length > 0) {
      queryBase = `job_name:${jobNameToFilter} AND ${queryBase}`;
    }
    const partnerEmails = partners
      .filter((p) => p.contacts)
      .reduce((all, p) => [...all, ...p.contacts], [])
      .filter((c) => c.email)
      .map((c) => {
        return c.email.toLowerCase();
      });
    const query =
      partnerEmails
        .reduce((query_construct: string, email: string) => {
          return `${query_construct} customer.email:${email} OR`;
        }, queryBase)
        .slice(0, -3) + ')';
    let ticketFetch;
    try {
      ticketFetch = await FetchTickets(query);
    } catch (error) {
      console.error('Failed to fetch potential partner tickets:', error);
      ticketFetch = null;
    }
    if (!ticketFetch || !ticketFetch.tickets || !ticketFetch.users) return [];
    return Object.keys(ticketFetch.tickets).map((k) => {
      const partnersCustomerId = ticketFetch.tickets[k].customer_id;
      return {
        ticket: ticketFetch.tickets[k],
        email: ticketFetch.users[partnersCustomerId].email,
      };
    });
  }

  matchPartnerWithTickets(
    partners,
    ticketsWithEmail: TicketWithEmail[],
  ): any[] {
    return partners.map((p) => {
      if (p.contacts.length) {
        const contactsWithEmail = p.contacts.filter((c) => c.email);
        const partnerTickets = ticketsWithEmail.filter(
          (t) =>
            t.email &&
            contactsWithEmail.length &&
            contactsWithEmail
              .map((c) => c.email.toLowerCase())
              .includes(t.email.toLowerCase()),
        );
        if (partnerTickets.length) {
          return {
            ...p,
            ...{ onboardingTicket: partnerTickets[0] },
          };
        }
      }
      return {
        ...p,
        ...{ onboardingTicket: undefined },
      };
    });
  }
  async fetchRejectionReasons() {
    const rejectJobReasons = 'Reject_Job_Reasons';
    return axios
      .get('/data-api/sf-picklist', {
        params: {
          api_names: rejectJobReasons,
          values_only: true,
        },
      })
      .then((r) =>
        r.data?.[rejectJobReasons]?.Metadata?.customValue.map(
          (v) => v.valueName,
        ),
      )
      .catch(() => {
        return [];
      });
  }
  async fetchContacts(accountIds) {
    const queryString = RequestQueryBuilder.create({
      filter: {
        field: 'account.id',
        operator: CondOperator.IN,
        value: accountIds,
      },
      sort: [{ field: 'createdAt', order: 'ASC' }],
    }).query();
    return axios
      .get(`/data-api/contact?${queryString}`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return [];
      });
  }
}
