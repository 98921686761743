
import { LiveAgent } from '@gid/vue-common/components/ticketing/interface/ticketing';
import moment from 'moment';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import LiveAgentMessage from './LiveAgentMessage.vue';
import LiveLiveAgentMessageNew from './LiveAgentMessageNew.vue';
@Component({
  components: {
    LiveLiveAgentMessageNew,
    LiveAgentMessage,
  },
  computed: mapGetters(['environment']),
})
export default class LiveAgentTicket extends Vue {
  @Prop({ default: null }) ticket!: LiveAgent.Ticket;
  @Prop({ default: null }) ticketId!: string;
  @Prop({ default: null }) departments!: LiveAgent.Department[];
  @Prop({ default: null }) refreshTickets!: () => void;

  showNewMessage: boolean = false;
  showMessage: boolean = false;

  formatDate(dateString: string) {
    return moment(dateString).format('MMM D, YYYY h:mm A');
  }
  get ticketDepartment(): string {
    if (!this.departments || !this.departments.length) return '';
    return (
      this.departments?.find(
        (dept) => dept.department_id === this.ticket.departmentId,
      )?.name || ''
    );
  }
  get liveAgentUrl(): string {
    return `https://getitdone.ladesk.com/agent/index.php#Conversation;code=${this.ticketId}`;
  }
  @Emit('message-created')
  message_created() {
    return true;
  }
}
