
import axios from 'axios';
import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import RichTextEditor from '@gid/vue-common/components/RichTextEditor.vue';

import Article from './Article.vue';
import ArticleNew from './ArticleNew.vue';
import { Zammad } from '@gid/vue-common/components/ticketing/interface/ticketing';
@Component({
  components: {
    Article,
    ArticleNew,
    RichTextEditor,
  },
  computed: mapGetters(['environment']),
})
export default class Ticket extends Vue {
  @Prop({ default: null }) ticket!: Zammad.TicketInterface;
  @Prop({ default: null }) ticketId!: number;
  @Prop({ default: null }) allTicketStates!: any[];
  @Prop({ default: null }) jobName!: string;
  @Prop({ default: null }) groups!: Zammad.Group[];
  @Prop({ default: null }) zammadUsers!: Zammad.ZammadUser[];
  @Prop({ default: null }) jobContacts!: Zammad.JobContact[];

  articles: Zammad.ArticleInterface[] = [];
  showAddArticle: boolean = false;
  newArticle: Zammad.ArticleInterface = this.blankNewArticle;
  environment!: string;

  created() {
    this.refreshArticles();
  }
  get locale() {
    return mapGetters(['locale']);
  }
  get ticketOwner(): string {
    if (!this.zammadUsers.length) {
      return '';
    }
    return this.zammadUsers.filter((a) => a.id == this.ticket?.owner_id)[0]
      ?.email;
  }
  get userZammad(): Zammad.ZammadUser {
    return Zammad.UserZammadByEmail(this.zammadUsers, this.userEmail);
  }
  get ticketCustomerEmail(): string {
    if (!this.zammadUsers || this.zammadUsers.length < 1) return '';
    return this.zammadUsers.filter((u) => u.id === this.ticket.customer_id)?.[0]
      ?.email;
  }
  get ticketGroup(): string {
    if (!this.groups || !this.groups.length) return '';
    return this.groups.filter((g) => g.id == this.ticket.group_id)[0]?.name;
  }
  get ticketCreator(): string {
    return this.zammadUsers.filter((a) => a.id == this.ticket?.created_by_id)[0]
      ?.email;
  }
  get blankNewArticle(): Zammad.ArticleInterface {
    return {
      ...Zammad.blankArticle,
      ...{
        ticket_id: this.ticketId,
        type: Zammad.ArticleTypesEnum.NOTE,
        internal: false,
        time_unit: '15',
        from: this.userEmail,
      },
    };
  }
  get t(): any {
    return this.ticket;
  }
  get status(): any {
    if (!this.allTicketStates || !this.allTicketStates.length) return undefined;
    return this.allTicketStates.filter((s) => s?.id === this.t?.state_id)[0];
  }
  get statusName(): string {
    return this.status ? this.status?.name : '';
  }

  get zammadUrl() {
    return `https://support.getitdone.rocks/#ticket/zoom/${this.ticketId}`;
  }
  get userEmail() {
    return this.$store.state.auth.user?.contact?.email;
  }
  async updateTicketJobName() {
    if (this.ticket.job_name.includes(this.jobName)) {
      return true;
    }
    let newTicketJobName = this.ticket.job_name;
    if (this.ticket.job_name.length > 0) {
      newTicketJobName = `${this.ticket.job_name}, ${this.jobName}`;
    }
    const res = await axios.put(
      `/zammad/api-proxy/tickets/${this.ticket.id}`,
      {
        job_name: newTicketJobName,
      },
      {
        headers: { 'X-On-Behalf-Of': this.userEmail },
      },
    );
  }
  async loadArticles() {
    if (this.ticketId) {
      axios
        .get(`/zammad/api-proxy/ticket_articles/by_ticket/${this.ticketId}`, {
          params: {
            expand: true,
            sort_by: 'created_at',
            order_by: 'desc',
          },
        })
        .then((response) => {
          this.articles = response?.data;
        });
    }
  }
  createdArticleCallback() {
    this.updateTicketJobName();
    this.refreshArticles();
  }
  resetAddArticle() {
    this.showAddArticle = false;
    this.newArticle = this.blankNewArticle;
  }
  refreshArticles() {
    this.loadArticles();
    this.resetAddArticle();
  }
}
