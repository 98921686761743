<template>
  <div>
    <template v-if="variants.includes('picture')">
      <h5>{{ $t('upload-picture') }}</h5>
      <files-selector
        @input="selectedFilesBefore = $event"
        :result-as-array="true"
        :maxFiles="1"
      />
    </template>
    <template v-if="variants.includes('picture_before_after')">
      <h5>{{ $t('upload-picture-before-start') }}</h5>
      <files-selector
        @input="selectedFilesBefore = $event"
        :result-as-array="true"
        :maxFiles="1"
      />
      <h5>{{ $t('upload-picture-after-finishing') }}</h5>
      <files-selector
        @input="selectedFilesAfter = $event"
        :result-as-array="true"
        :maxFiles="1"
      />
    </template>
    <template v-if="variants.includes('protocol')">
      <h5>{{ $t('upload-signed-protocol') }}</h5>
      <b-form-checkbox v-model="overwriteDoc">
        {{ $t('overwrite-documentation') }}
      </b-form-checkbox>
      <files-selector
        @input="selectedFilesProtocols = $event"
        :result-as-array="true"
      />
    </template>
  </div>
</template>

<script>
import BaseInput from '@gid/vue-common/components/inputs/mixins/BaseInput';
import FilesSelector from '@gid/vue-common/components/inputs/FilesSelector.vue';

export default {
  mixins: [BaseInput],
  components: {
    FilesSelector,
  },
  props: {
    variants: Array,
    inputName: {
      type: String,
    },
  },
  data() {
    return {
      selectedFilesBefore: [],
      selectedFilesAfter: [],
      selectedFilesProtocols: [],
      overwriteDoc: false,
    };
  },
  watch: {
    selectedFilesBefore() {
      this.emitChange(
        this.selectedFilesBefore.concat(this.selectedFilesAfter),
        'picture_before_after',
      );
    },
    selectedFilesAfter() {
      this.emitChange(
        this.selectedFilesBefore.concat(this.selectedFilesAfter),
        'picture_before_after',
      );
    },
    selectedFilesProtocols() {
      this.emitChange(this.selectedFilesProtocols, 'protocol', {
        overwrite: this.overwriteDoc,
      });
    },
    overwriteDoc() {
      this.emitChange(this.selectedFilesProtocols, 'protocol', {
        overwrite: this.overwriteDoc,
      });
    },
  },
  computed: {
    currentDate() {
      return this.$moment().format('L');
    },
  },
  methods: {
    emitChange(files, variant, extra) {
      const value =
        files && files.length > 0
          ? {
              files,
              variant,
              extra,
            }
          : null;
      this.commitValue(value);
    },
  },
};
</script>
