
import { Vue, Component, Ref, Prop, Watch, Emit } from 'vue-property-decorator';
import { GeneralInvoicingTargetEnum } from '@gid/models';
import { Zammad } from '@gid/vue-common/components/ticketing/interface/ticketing';
@Component({
  components: {},
})
export default class CallContacts extends Vue {
  @Prop({ default: null }) jobContacts!: Zammad.JobContact[];
  @Prop({ default: null }) jobRolesFilter!: GeneralInvoicingTargetEnum[];
  get visibleSingle(): boolean {
    return this.contactList && this.contactList.length == 1;
  }
  get visibleDropdown(): boolean {
    return this.contactList && this.contactList.length > 1;
  }
  get contactPhone(): object {
    return (c: Zammad.JobContact): string | null => {
      const match = ((c.mobilePhone ? c.mobilePhone : c.phone) || '').match(
        /[+,\d]/g,
      );
      if (match) return match.join('');
      return null;
    };
  }
  get contactList(): Zammad.JobContact[] {
    if (!this.jobContacts || this.jobContacts.length < 0) return [];
    return this.jobContacts
      .filter((jc) => !!jc.jobRole)
      .filter(
        (jc) =>
          (jc.phone || jc.mobilePhone) &&
          (!this.jobRolesFilter ||
            (jc.jobRole && this.jobRolesFilter.includes(jc.jobRole))),
      )
      .sort((a, b) =>
        (a.lastName || '') < (b.lastName || '')
          ? -1
          : (a.lastName || '') > (b.lastName || '')
          ? 1
          : 0,
      );
  }
  @Emit('call-init')
  callInit() {
    return true;
  }
  link(url: string) {
    window.open(url);
    this.callInit();
  }
}
