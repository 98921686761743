<template>
  <span>
    <span :title="$t('price.list')" v-b-tooltip.hover>
      {{ job_view.job.price_lists | currency }}
    </span>
    <br />
    <span :title="$t('price.selling')" v-b-tooltip.hover>
      {{ job_view.job.price_selling | currency }}
    </span>
    <br />
    <span :title="$t('price.purchasing')" v-b-tooltip.hover>
      {{ job_view.job.price_purchasing | currency }}
    </span>
    <br />
    <span v-if="isMarginNegative" :style="{ color: 'red' }">
      {{ $t('negative-margin') }}
    </span>
    <b-badge v-if="firstCancelationWithPriceChange">
      {{ $t('price.changed') }}
    </b-badge>
    <b-badge
      variant="primary"
      v-if="job_view.total_paid && job_view.total_paid"
    >
      {{ $t('paid') }} - {{ job_view.total_paid | currency }} ({{
        (job_view.total_paid /
          (1 + job_view.opportunity.currency_tax_config.vat_rate / 100))
          | currency
      }}
      Netto)
    </b-badge>
  </span>
</template>

<script>
import PriceHasChanged from '@gid/vue-common/components/mixins/PriceHasChanged.js';

export default {
  mixins: [PriceHasChanged],
  props: ['job_view'],
  methods: {
    getPriceChangeConfig() {
      return {
        job_view: this.job_view,
        price_selling: true,
        price_purchasing: true,
      };
    },
  },
  computed: {
    isMarginNegative() {
      return this.job_view.revenue - this.job_view.costs < 0;
    },
  },
};
</script>
