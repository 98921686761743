
import { mapGetters } from 'vuex';
import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator';
import HtmlRich from '@gid/vue-common/components/HtmlRich.vue';
import Attachment from './Attachment.vue';

import { Zammad } from '@gid/vue-common/components/ticketing/interface/ticketing';
@Component({
  components: {
    Attachment,
    HtmlRich,
  },
  computed: {
    ...mapGetters(['access_token']),
  },
})
export default class Article extends Vue {
  @Prop({ default: null }) article!: Zammad.ArticleInterface;
  @Prop({ default: null }) ticketId!: Number;
  @Prop({ default: null }) allTicketStates!: Object;
  @Prop({ default: null }) jobName!: String;

  protected access_token!: string;

  get articleBody() {
    return this.article.body.replace(
      /\/api\/v1\/ticket_attachment\/(\d+)\/(\d+)\/(\d+)\?view=inline/g,
      `/zammad/api-proxy/ticket_attachment/$1/$2/$3?jwt=${this.access_token}`,
    );
  }
}
