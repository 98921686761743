
import { Vue, Component, Ref, Prop, Watch, Emit } from 'vue-property-decorator';
import Ticket from '../../../components/ticketing/Ticket.vue';
import TicketNew from '../../../components/ticketing/TicketNew.vue';
import { Zammad } from '@gid/vue-common/components/ticketing/interface/ticketing';
import RejectJob from './RejectJob.vue';
import CallContacts from '../../../components/ticketing/CallContacts.vue';
import { Account, GeneralInvoicingTargetEnum } from '@gid/models';
@Component({
  components: {
    CallContacts,
    RejectJob,
    Ticket,
    TicketNew,
  },
})
export default class PartnerListItem extends Vue {
  @Prop({ default: null }) partner!: any;
  @Prop({ default: null }) jobName!: string;
  @Prop({ default: null }) jobApiId!: string;
  @Prop({ default: null }) ticketCreation!: boolean;
  @Prop({ default: null }) rejectionReasons!: string[];

  tickets: Zammad.TicketInterface[] = [];
  articleTypePreselect: string = Zammad.ArticleTypesEnum.NOTE;
  showAddTicket: boolean = false;
  showRejectJob: boolean = false;

  get onboardingTicket(): Zammad.TicketInterface {
    return this.partner?.['onboardingTicket']?.ticket;
  }
  get partnerRole(): GeneralInvoicingTargetEnum[] {
    return [GeneralInvoicingTargetEnum.PARTNER];
  }
  get partnerJobContacts(): Zammad.JobContact[] | object {
    if (this.partner.contacts && this.partner.contacts.length) {
      return this.partner.contacts.map((c) => {
        return {
          ...c,
          ...{ jobRole: GeneralInvoicingTargetEnum.PARTNER },
        };
      });
    }
    return [];
  }
  prepareCallTicket() {
    this.showAddTicket = true;
    this.articleTypePreselect = Zammad.ArticleTypesEnum.PHONE;
  }
  @Emit('ticket-created')
  triggerRefreshTickets() {
    return true;
  }
  @Emit('job-rejected')
  triggerRefreshPartners() {
    this.showRejectJob = false;
    return true;
  }
}
