<template>
  <b-modal
    size="lg"
    ref="cancellationsModal"
    title="History of cancellations"
    ok-only
  >
    <b-table small :items="cancellationsHistory" :fields="fields">
      <template #cell(created_date)="{ value }">
        {{ value | moment('L') }}
      </template>
      <template #cell(_price)="{ item }">
        <span v-if="priceChanged(item)">
          <s class="text-muted mr-1" v-if="priceChanged(item)">
            <span :title="$t('price.list')" v-b-tooltip.hover>
              {{ item.old_price_lists | currency }}
            </span>
            /
            <span :title="$t('price.selling')" v-b-tooltip.hover>
              {{ item.old_price_selling | currency }}
            </span>
            /
            <span :title="$t('price.purchasing')" v-b-tooltip.hover>
              {{ item.old_price_purchasing | currency }}
            </span>
          </s>
          <br />
          <span :title="$t('price.list')" v-b-tooltip.hover>
            {{ item.new_price_lists | currency }}
          </span>
          /
          <span :title="$t('price.selling')" v-b-tooltip.hover>
            {{ item.new_price_selling | currency }}
          </span>
          /
          <span :title="$t('price.purchasing')" v-b-tooltip.hover>
            {{ item.new_price_purchasing | currency }}
          </span>
        </span>
        <span v-else class="text-muted">-</span>
      </template>
      <template #cell(_label)="{ item }">
        <template v-if="item.definition">
          {{ item.definition.name }} - {{ item.definition.description[locale] }}
        </template>
        <template v-else>-</template>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    cancellationsHistory: Array,
  },
  data() {
    return {
      fields: [
        {
          key: 'created_date',
          label: 'Date',
        },
        {
          key: '_label',
          label: 'Label',
        },
        {
          key: '_price',
          label: this.$t('job.table.heading.price'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['locale']),
  },
  methods: {
    show() {
      this.$refs.cancellationsModal.show();
    },
    priceChanged(item) {
      return (
        (item.old_price_lists &&
          item.old_price_lists != item.new_price_lists) ||
        (item.old_price_selling &&
          item.old_price_selling != item.new_price_selling) ||
        (item.old_price_purchasing &&
          item.old_price_purchasing != item.new_price_purchasing)
      );
    },
  },
};
</script>
