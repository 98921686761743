<template>
  <div class="mb-2">
    <div
      class="d-flex align-items-center justify-content-between input-bar"
      @click="toggleShow"
    >
      <h5 class="mb-0">
        {{ title }}
        <em>
          <small :class="infoColor" v-if="!previewOnly">
            - {{ infoLabel }}
          </small>
        </em>
      </h5>
      <div @click.stop>
        <b-form-checkbox
          inline
          switch
          :checked="edit"
          @change="$emit('update:edit', $event)"
          class="gid-checkbox-right mr-2"
          v-if="showEdit"
        >
          <small class="text-muted">{{ $t('edit') }}</small>
        </b-form-checkbox>
        <b-form-checkbox
          inline
          switch
          v-model="showInternal"
          @change="$emit('update:show', $event)"
          class="gid-checkbox-right"
          :disabled="edit"
        >
          <small class="text-muted">show</small>
        </b-form-checkbox>
      </div>
    </div>
    <div v-if="showHint">
      <small class="text-muted">{{ hint }}</small>
    </div>
    <div class="pt-2" v-show="showInternal || edit">
      <slot />
    </div>
    <hr class="my-2" />
  </div>
</template>

<script>
export default {
  props: {
    inputName: String,
    title: String,
    type: String,
    edit: Boolean,
    previewOnly: Boolean,
    show: Boolean,
  },
  data() {
    return {
      showInternal: null,
    };
  },
  watch: {
    edit(newValue) {
      if (newValue) {
        this.showInternal = true;
      }
    },
    show(newValue) {
      this.showInternal = newValue;
    },
  },
  computed: {
    showEdit() {
      return ['already_set', 'optional'].includes(this.type);
    },
    infoLabel() {
      return this.$t(`inputs.labels.${this.type}`);
    },
    infoColor() {
      let color;
      switch (this.type) {
        case 'remove':
          color = 'danger';
          break;
        case 'required':
          color = 'primary';
          break;
        case 'already_set':
          color = 'muted';
          break;
        case 'forced':
          color = 'danger';
          break;
        default:
          color = 'body';
      }
      return `text-${color}`;
    },
    showHint() {
      return this.$te(`inputs.hints.${this.inputName}`);
    },
    hint() {
      return this.$t(`inputs.hints.${this.inputName}`);
    },
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
      this.showInternal = this.show;
      this.$emit('update:show', this.showInternal);
    },
  },
  created() {
    this.showInternal = this.show;
    if (this.edit) {
      this.showInternal = true;
    }
  },
};
</script>

<style lang="scss">
.gid-checkbox-right {
  padding-left: 0;
  padding-right: 2.25rem;
  margin-right: 0;

  &.custom-switch {
    .custom-control-label {
      &::before {
        left: auto;
        right: -2.25rem;
      }
      &::after {
        left: auto;
        right: calc(-1.5rem + 2px);
      }
    }
  }
}
.input-bar:hover {
  cursor: pointer;
  background-color: rgba(224, 224, 224, 0.4);
  box-shadow: 0 0 6px 0 rgba(224, 224, 224, 0.4);
}
</style>
