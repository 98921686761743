
import AxiosStream from 'axios-stream';
import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator';
import HtmlRich from '@gid/vue-common/components/HtmlRich.vue';

import { Zammad } from '@gid/vue-common/components/ticketing/interface/ticketing';
import { mapGetters } from 'vuex';
@Component({
  components: {
    HtmlRich,
  },
  computed: {
    ...mapGetters(['access_token']),
  },
})
export default class Attachment extends Vue {
  @Prop({ default: null }) articleId!: number;
  @Prop({ default: null }) ticketId!: number;
  @Prop({ default: null }) attachment!: Zammad.AttachmentInterface;

  protected access_token!: string;

  get size(): string {
    return ((this.attachment?.size || 0) / 1024).toFixed(2) + ' KB';
  }
  get downloadURL(): string {
    if (!this.ticketId || !this.articleId || !this.attachment.id) {
      return '#';
    }
    return `/zammad/api-proxy/ticket_attachment/${this.ticketId}/${this.articleId}/${this.attachment?.id}`;
  }

  download() {
    return AxiosStream.download(this.attachment.filename, '', {
      method: 'get',
      url: this.downloadURL,
      headers: {
        Authorization: `Bearer ${this.access_token}`,
      },
    });
  }
}
