
import { Vue, Component, Prop } from 'vue-property-decorator';
import axios from 'axios';
import { JobStatusEnum } from '@gid/models/dist/entities/job-status-enum';
import moment from 'moment';

type TimelineListItem = {
  time_stamp: any;
  status: string;
};

@Component
export default class Timeline extends Vue {
  @Prop() readonly job!: any;

  list: TimelineListItem[] = [];
  steps = [
    JobStatusEnum.OPEN,
    JobStatusEnum.ACCEPTED,
    JobStatusEnum.APPOINTMENT_CONFIRMED,
    JobStatusEnum.FINISHED,
  ];

  async created() {
    const response = await axios.get(
      `/api/admin/jobs/${this.job?.sfid}/log-entries`,
    );

    const sortedResponseArray = this.processData(response.data);

    const result: TimelineListItem[] = [
      { status: 'created', time_stamp: this.job.job.created_date },
      ...sortedResponseArray,
    ];

    this.list = result;
  }

  get formattedTimestamp() {
    return (time_stamp) =>
      time_stamp ? new Date(time_stamp).toLocaleString() : '';
  }

  processData(data): TimelineListItem[] {
    const transformedData = data
      .filter((entry) => this.steps.includes(entry.new_status))
      .map((entry) => {
        return {
          time_stamp: entry.time_stamp,
          status: entry.new_status,
        };
      });

    const sortedData = [
      ...transformedData,
      ...this.steps
        .filter((entry) => !transformedData.find((e) => e.status === entry))
        .map((entry) => ({ status: entry, time_stamp: '' })),
    ]
      .sort(
        (a, b) => this.steps.indexOf(a.status) - this.steps.indexOf(b.status),
      )
      .filter((entry, i, arr) => arr[i - 1]?.status != entry.status)
      .map((entry, i, arr) => {
        if (
          i > 0 &&
          new Date(arr[i - 1]?.time_stamp).getTime() >
            new Date(entry.time_stamp).getTime()
        ) {
          return { ...entry, time_stamp: '' };
        }
        return entry;
      });

    return sortedData;
  }

  calculateDifference(time_stamp, subtractor) {
    const millis =
      new Date(subtractor).getTime() - new Date(time_stamp).getTime();

    if (Number.isNaN(millis) || millis < 0) return 'N/A';

    const minutes = Math.floor(millis / (1000 * 60));
    const hours = Math.floor(millis / (1000 * 60 * 60));
    const days = Math.floor(millis / (1000 * 60 * 60 * 24));

    if (hours < 1) {
      return `${minutes} minutes`;
    } else if (days < 1) {
      return `${hours} hours`;
    }
    return `${days} days`;
  }

  capitalize(str) {
    return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
  }
}
